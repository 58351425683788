import { Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../App";
import { ABB, BJE } from "../../constants/Theme";
import { ReactComponent as DeleteIcon } from "../../pages/Configurator/Icons/delete-icon.svg";

interface IDotsMenuProps {
  anchorEl: any;
  handleClose: () => void;
  handleDeleteFavoriteDesign: () => void;
  handleCreateWithDesign: () => void;
}

const AbbDotsMenu: React.FC<IDotsMenuProps> = ({
  anchorEl,
  handleClose,
  handleDeleteFavoriteDesign,
  handleCreateWithDesign,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Menu
        id="recentProjectMenu"
        anchorEl={anchorEl}
        keepMounted
        onClick={handleClose}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "180px",
            border: "1px solid var(--grey-20, #DBDBDB)",
          },
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleCreateWithDesign}>
          <Typography sx={{ fontSize: 16, fontWeight: 400 }}>{t("favoriteDesigns.actions.create")}</Typography>
        </MenuItem>

        <hr style={{ color: "#F1EDED", margin: 0 }} />

        <MenuItem
          sx={{ height: 47, color: "#FF000F" }}
          onClick={handleDeleteFavoriteDesign}
        >
          <DeleteIcon />
          <Typography sx={{ marginLeft: "5px", fontSize: 14, fontWeight: 400 }}>
            {t("favoriteDesigns.actions.delete")}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

const BjeDotsMenu: React.FC<IDotsMenuProps> = ({
  anchorEl,
  handleClose,
  handleDeleteFavoriteDesign,
  handleCreateWithDesign,
}) => {
  const { t } = useTranslation();

  return (
    <Menu
      id="recentProjectMenu"
      anchorEl={anchorEl}
      keepMounted
      onClick={handleClose}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      PaperProps={{
        style: {
          width: "215px",
          height: "122px",
          border: "1px solid var(--grey-20, #DBDBDB)",
        },
      }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuItem onClick={handleCreateWithDesign}>
        <Typography sx={{ fontSize: 16, fontWeight: 400 }}>{t("favoriteDesigns.actions.create")}</Typography>
      </MenuItem>

      <hr style={{ color: "#F1EDED", margin: 0 }} />

      <MenuItem
        sx={{ color: "#FF000F" }}
        onClick={handleDeleteFavoriteDesign}
      >
        <DeleteIcon />
        <Typography sx={{ fontSize: 16, fontWeight: 400 }}>{t("favoriteDesigns.actions.delete")}</Typography>
      </MenuItem>
    </Menu>
  );
};

const DotsMenuFavDesigns: React.FC<IDotsMenuProps> = (rest) => {
  const theme = useTheme() as FTSTheme;

  return <>{(theme.name == ABB && <AbbDotsMenu {...rest} />) || (theme.name == BJE && <BjeDotsMenu {...rest} />)}</>;
};

export default DotsMenuFavDesigns;
