export const ICON = "ICON";
export const TEXT = "TEXT";
export const WITH_TEXT = "WITH_TEXT";
export const WITHOUT_TEXT = "WITHOUT_TEXT";
export const TOP_LINE = "TOP_LINE";
export const BOTTOM_LINE = "BOTTOM_LINE";
export const SEPARATION_LINE = "SEPARATION_LINE";
export const SEPARATION_HALF_LINE = "SEPARATION_HALF_LINE";
export const SEPARATION_LINE_VERTICAL = "SEPARATION_LINE_VERTICAL";
export const SEPARATION_LINE_HORIZONTAL = "SEPARATION_LINE_HORIZONTAL";

export const POSITIONS = {
  TOP: "TOP",
  BOTTOM: "BOTTOM",
  MIDDLE: "MIDDLE",
  RIGHT: "RIGHT",
  LEFT: "LEFT",
  TOP_LEFT: "TOP_LEFT",
  TOP_RIGHT: "TOP_RIGHT",
  BOTTOM_LEFT: "BOTTOM_LEFT",
  BOTTOM_RIGHT: "BOTTOM_RIGHT",
  VERTICAL: "VERTICAL",
  HORIZONTAL: "HORIZONTAL",
  BUTTON_LEFT: "BUTTON_LEFT",
  BUTTON_RIGHT: "BUTTON_RIGHT",
  BUTTON_TOP: "BUTTON_TOP",
  BUTTON_BOTTOM: "BUTTON_BOTTOM",
  BUTTONS_TOP: "BUTTONS_TOP",
  BUTTONS_RIGHT: "BUTTONS_RIGHT",
  BUTTONS_BOTTOM: "BUTTONS_BOTTOM",
  BUTTONS_LEFT: "BUTTONS_LEFT",
  ROCKER_LEFT: "ROCKER_LEFT",
  ROCKER_TOP: "ROCKER_TOP",
  ROCKER_RIGHT: "ROCKER_RIGHT",
  ROCKER_BOTTOM: "ROCKER_BOTTOM",
  SLIDER_LEFT: "SLIDER_LEFT",
  SLIDER_RIGHT: "SLIDER_RIGHT",
  VERTICAL_ROCKER: "VERTICAL_ROCKER",
  HORIZONTAL_ROCKER: "HORIZONTAL_ROCKER",
};

export const LAYOUTS = {
  ROCKER_SLIDER: "ROCKER_SLIDER",
  SLIDER_ROCKER: "SLIDER_ROCKER",
  SLIDER: "SLIDER",
  ROCKER: "ROCKER",
  BUTTON: "BUTTON",
  BUTTONS: "BUTTONS",
  SLIDER_SLIDER: "SLIDER_SLIDER",
  BUTTON_SLIDER: "BUTTON_SLIDER",
  SLIDER_BUTTON: "SLIDER_BUTTON",
  BUTTON_BUTTON: "BUTTON_BUTTON",
  ROCKER_ROCKER: "ROCKER_ROCKER",
  ROCKER_BUTTON: "ROCKER_BUTTON",
  BUTTON_ROCKER: "BUTTON_ROCKER",
  BUTTONS_SLIDER: "BUTTONS_SLIDER",
  SLIDER_BUTTONS: "SLIDER_BUTTONS",
  BUTTONS_ROCKER: "BUTTONS_ROCKER",
  ROCKER_BUTTONS: "ROCKER_BUTTONS",
  FOUR_BUTTONS: "FOUR_BUTTONS",
};

export const ELEMENTS = {
  ONE: "1",
  TWO: "2",
  THREE: "3",
  FOUR: "4",
  FIVE: "5",
  SIX: "6",
  SEVEN: "7",
};

export const CONTROL_ICON_SIZES = {
  80: 5,
  90: 6,
  100: 7,
  110: 8,
  120: 9,
};

export const FUNCTIONAL_SMALL_ICON_SIZES = {
  90: 4,
  100: 5,
  110: 6,
  120: 7,
};

export const TEXT_SIZES = {
  10: 3.35,
  12: 3.9,
};
