export const PAGE_INITIAL_INDEX = 0;
export const INSERT_DETAILS_SINGLE_ELEMENT = 0;
export const ROWS_PER_PAGE = 5;
export const ROWS_PER_PAGE_DIALOG = 4;
export const NUMBER_OF_SINGLE_COVER = 1;
export const NUMBER_OF_SINGLE_SWITCH = 1;
export const MAX_NUMBER_OF_COVERS = 5;
export const DECIMAL_PLACES = 2;
export const TEN_PERCENT = 10;
export const HUNDRED_PERCENT = 100;
