import { Box, Grid, Tab, Tabs, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStoreState } from "../../../hooks";
import "./DesignPreviewDialog.css";
import { useLocation } from "react-router-dom";
import { DESIGN_SUMMARY_PATH, FAVORITE_DESIGNS_PATH } from "../../../constants/Paths";
import FavoriteDesignsCheckbox from "../../../components/FavoriteDesignsCheckbox/FavoriteDesignsCheckbox";
import { IFoundFavoriteDesigns } from "../../../interfaces/IFoundFavoriteDesigns";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface IProps {
  checkedFavoriteDesigns?: IFoundFavoriteDesigns[];
  setCheckedFavoriteDesigns?: React.Dispatch<React.SetStateAction<IFoundFavoriteDesigns[]>>;
}

const DesignPreviewTabs: React.FC<IProps> = ({ checkedFavoriteDesigns, setCheckedFavoriteDesigns }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const theme = useTheme();
  const { productDesignPreview } = useStoreState((state) => state.designs.data);
  const isDesignSummaryPage = pathname === DESIGN_SUMMARY_PATH;
  const isFavoriteDesignsPage = pathname === FAVORITE_DESIGNS_PATH;

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {!isFavoriteDesignsPage && (
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="cover tabs"
            TabIndicatorProps={{ className: "designPreview__tabIndicator" }}
          >
            {productDesignPreview?.insertDetails.map((item, index) => (
              <Tab
                sx={{
                  color: "#000000",
                  textTransform: "capitalize",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "140%",
                  "&.Mui-selected": { fontWeight: 700, color: theme.palette.primary.main },
                }}
                label={`${t("design_preview_tabs.label.cover")} ${index + 1}`}
                key={`cover-tab-key-${index}`}
              />
            ))}
          </Tabs>
        </Box>
      )}

      {productDesignPreview?.insertDetails.map((item, index) => (
        <TabPanel
          value={value}
          index={index}
          key={`cover-tabpanel-key-${index}`}
        >
          <Grid item>
            <Typography className="designPreview__tabs_container_title">{`${productDesignPreview.design?.name}, ${t(`insertcolors.${item.insertColor?.code}`)}`}</Typography>
          </Grid>
          <Grid
            container
            sx={{ justifyContent: "space-between" }}
          >
            <Grid item>
              <Typography>
                <span className="designPreview__tabs_container_key">{t("DesignPreviewTabs_ArticleNumber")}:</span>
                <span className="designPreview__tabs_container_value">
                  {item.insertColor?.insertCompositionCode?.articleNumber}
                </span>
              </Typography>
              <Typography>
                <span className="designPreview__tabs_container_key">{t("DesignPreviewTabs_ProductCode")}:</span>
                <span className="designPreview__tabs_container_value">
                  {item.insertColor?.insertCompositionCode?.orderCode}
                </span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <span className="designPreview__tabs_container_key">{t("DesignPreviewTabs_EAN")}:</span>
                <span className="designPreview__tabs_container_value">
                  {item.insertColor?.insertCompositionCode?.ean}
                </span>
              </Typography>
              <Typography>
                <span className="designPreview__tabs_container_key">{t("DesignPreviewTabs_DesignId")}:</span>
                <span className="designPreview__tabs_container_value">{item.coverDesignId}</span>
              </Typography>
            </Grid>
            <Grid item>
              {isDesignSummaryPage && checkedFavoriteDesigns && setCheckedFavoriteDesigns && (
                <FavoriteDesignsCheckbox
                  item={item}
                  checkedFavoriteDesigns={checkedFavoriteDesigns}
                  setCheckedFavoriteDesigns={setCheckedFavoriteDesigns}
                  numberOfCover={index}
                />
              )}
            </Grid>
          </Grid>
        </TabPanel>
      ))}
    </Box>
  );
};

export default DesignPreviewTabs;
