import { LoadingButton } from "@mui/lab";
import { Autocomplete, Divider, FormControl, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { useAuth } from "../../auth/utils";
import LoadingSkeleton from "../../components/Dialog/CountryLanguage/Skeleton";
import { useStoreActions, useStoreState } from "../../hooks";
import { ILoggedUser } from "../../interfaces/DTO/ILoggedUser";
import { ICountry } from "../../interfaces/ICountry";
import { ILanguage } from "../../interfaces/ILanguage";
import UserService from "../../services/UserService";
import ArrowDownIcon from "../Configurator/Icons/arrow-down.svg";
import { AxiosError, HttpStatusCode } from "axios";
import { useTranslation } from "react-i18next";
import FitText from "../../components/FitText/FitText";

const SettingsPrivacyTab: React.FC = () => {
  const { t } = useTranslation();
  const successMessage = t("profile_page.tab_settings.label.on_save_successfully_message");
  const { user } = useAuth();
  const { thunkOpenSnackBar } = useStoreActions((actions) => actions.app);
  const { thunkGetDesigns } = useStoreActions((actions) => actions.designs);
  const { setLanguageCode, setCurrentUser } = useStoreActions((actions) => actions.auth);
  const currentUser = useStoreState((state) => state.auth.currentUser);
  const { countries } = useStoreState((state) => state.countries);
  const { languages } = useStoreState((state) => state.languages);

  const [loading, setLoading] = useState(false);
  const [changedLanguage, setChangedLanguage] = useState(false);

  const [countryValue, setCountryValue] = useState<ICountry | null>(null);
  const [languageValue, setLanguageValue] = useState<ILanguage | null>(null);

  const [previousCountryValue, setPreviousCountryValue] = useState<ICountry | null>(null);
  const [previousLanguageValue, setPreviousLanguageValue] = useState<ILanguage | null>(null);

  useEffect(() => {
    if (countries.length > 0 && languages.length > 0 && currentUser != null) {
      const userCountry = countries.find((x) => x.id == currentUser?.countryId) ?? countries[0];
      const userLanguage = languages.find((x) => x.id == currentUser?.languageId)!;

      setCountryValue(userCountry);
      setLanguageValue(userLanguage);
      setPreviousCountryValue(userCountry);
      setPreviousLanguageValue(userLanguage);
    }
  }, [countries, languages, currentUser]);

  const handleChangeCountry = (country: ICountry | null) => {
    setCountryValue(country);
    if (country) {
      const defaultLanguage = languages.find((x) => x.id == country.defaultLanguage)!;
      setLanguageValue(defaultLanguage);
    }
  };

  const handleChangeLanguage = (language: ILanguage | null) => {
    setLanguageValue(language);
  };

  const handleRefreshDesignsWithNewCoutry = (countryId: number) => {
    thunkGetDesigns(countryId);
  };

  const saveFormData = (e: React.FormEvent) => {
    setLoading(true);
    setChangedLanguage(true);
    const country = countries.find((x) => x.code == countryValue?.code)!;

    const countryId = country.id;
    const isElBridgeAvailable = country.isElBridgeAvailable;

    UserService.updateCountryAndLanguage(user?.email!, countryValue?.code!, languageValue?.id!)
      .then((response) => {
        setPreviousCountryValue(countryValue);
        setPreviousLanguageValue(languageValue);
        handleRefreshDesignsWithNewCoutry(countryId);
      })
      .catch((err: AxiosError<string>) => {
        setChangedLanguage(false);
        let message = t("profile_page.tab_settings.label.on_save_generic_error_message");
        if (err?.response?.status === HttpStatusCode.NotFound || err?.response?.status === HttpStatusCode.BadRequest) {
          message = t(err.response.data);
        }
        thunkOpenSnackBar({
          message: message,
          severity: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });

    const newUser: ILoggedUser = {
      email: currentUser?.email!,
      name: currentUser?.name!,
      countryId: countryId,
      languageId: languageValue?.id!,
      isElBridgeAvailable: isElBridgeAvailable,
      termsAndConditionsAccepted: currentUser?.termsAndConditionsAccepted!,
    };

    setCurrentUser(newUser);

    setLanguageCode(languages.find((x) => x.id == languageValue?.id)?.code!);
  };

  useEffect(() => {
    if (changedLanguage) {
      thunkOpenSnackBar({
        message: successMessage,
        severity: "success",
      });
      setChangedLanguage(false);
    }
  }, [successMessage]);

  return (
    <>
      <Grid container>
        <Grid
          item
          md={3}
        >
          <Typography fontSize={"24px"}>{t("profile_page.tab_settings.label.country_settings")}</Typography>
          <Typography
            fontSize={"16px"}
            color={"#757575"}
          >
            {t(`country.${previousCountryValue?.name!}`)}
          </Typography>

          <Divider sx={{ margin: "24px 0px" }} />

          <Typography
            fontSize={"16px"}
            fontWeight={"500"}
          >
            {t("profile_page.tab_settings.label.select_country")}
          </Typography>

          <FormControl
            sx={{ mb: "15px", width: "100%" }}
            size="small"
          >
            {countries.length == 0 && <LoadingSkeleton />}
            {countries.length > 0 && (
              <Autocomplete
                value={countryValue}
                defaultValue={countryValue}
                options={countries}
                disabled={loading}
                noOptionsText={t("profile_page.tab_settings.placeholder_no_options")}
                getOptionLabel={(options: ICountry) => t(`country.${options.name}`)}
                popupIcon={
                  <i>
                    <ReactSVG src={ArrowDownIcon} />
                  </i>
                }
                size="small"
                onChange={(event: any, newValue: ICountry | null) => {
                  handleChangeCountry(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={`${t("profile_page.tab_settings.placeholder_search")}`}
                    variant="outlined"
                  />
                )}
              />
            )}
          </FormControl>
        </Grid>

        <Grid
          item
          md={3}
          ml={"120px"}
        >
          <Typography fontSize={"24px"}>{t("profile_page.tab_settings.label.language_settings")}</Typography>
          <Typography
            fontSize={"16px"}
            color={"#757575"}
          >
            {t(`language.${previousLanguageValue?.name!}`)}
          </Typography>

          <Divider sx={{ margin: "24px 0px" }} />

          <Typography
            fontSize={"16px"}
            fontWeight={"500"}
          >
            {t("profile_page.tab_settings.label.select_language")}
          </Typography>

          <FormControl
            sx={{ width: "100%" }}
            size="small"
          >
            {languages.length == 0 && <LoadingSkeleton />}
            {languages.length > 0 && (
              <Autocomplete
                value={languageValue}
                defaultValue={languageValue}
                options={languages}
                disabled={loading}
                noOptionsText={t("profile_page.tab_settings.placeholder_no_options")}
                getOptionLabel={(options: ILanguage) => t(`language.${options.name}`)}
                popupIcon={
                  <i>
                    <ReactSVG src={ArrowDownIcon} />
                  </i>
                }
                size="small"
                onChange={(event: any, newValue: ILanguage | null) => {
                  handleChangeLanguage(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={`${t("profile_page.tab_settings.placeholder_search")}`}
                    variant="outlined"
                  />
                )}
              />
            )}
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        mt={"100px"}
        container
        justifyContent="center"
      >
        <LoadingButton
          disabled={countryValue == null || languageValue == null}
          sx={{ width: "193px" }}
          variant="contained"
          onClick={saveFormData}
          loading={loading}
        >
          <FitText>{t("profile_page.tab_settings.button.save")}</FitText>
        </LoadingButton>
      </Grid>
    </>
  );
};

export default SettingsPrivacyTab;
