import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import { Button } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const BackButton: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button
      variant="text"
      onClick={() => navigate(-1)}
      startIcon={<ChevronLeftOutlinedIcon sx={{ width: "28px", height: "28px" }} />}
      color="inherit"
      sx={{
        marginTop: "10px",
        paddingRight: "15px",
      }}
    >
      <span>{t("buttons.back")}</span>
    </Button>
  );
};

export default BackButton;
