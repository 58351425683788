import { Button, ButtonGroup, ButtonProps, IconButton, Typography, useTheme } from "@mui/material";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { FTSTheme } from "../../../../App";
import { ABB, BJE } from "../../../../constants/Theme";

interface IButtonProps extends ButtonProps {
  disabledDecrement: boolean;
  disabledIncrement: boolean;
  iconDecrementColor: string;
  iconIncrementColor: string;
  buttonDisabledColor: string;
  currentQuantity: string;
  handleDecrement: () => void;
  handleIncrement: () => void;
}

const AbbQuantityCounter: React.FC<IButtonProps> = ({
  disabledDecrement,
  disabledIncrement,
  iconDecrementColor,
  iconIncrementColor,
  buttonDisabledColor,
  currentQuantity,
  handleDecrement,
  handleIncrement,
}) => {
  return (
    <ButtonGroup
      size="large"
      aria-label="large button group"
      sx={{
        borderRadius: 1,
        border: "1px solid #DBDBDB",
        width: 128,
        height: 40,
      }}
    >
      <IconButton
        sx={{
          width: 40,
          height: 40,
        }}
        aria-label="subtract"
        color="primary"
        className="designSummaryTable_cell_iconquantity"
        onClick={handleDecrement}
        disabled={disabledDecrement}
      >
        <RemoveOutlinedIcon
          style={{
            fill: iconDecrementColor,
          }}
        />
      </IconButton>
      <Button
        disabled
        sx={{
          width: 48,
          height: 40,
        }}
        className={`designSummaryTable_cell_quantity_abb${buttonDisabledColor}`}
        variant="text"
      >
        <Typography sx={{ fontSize: 14, fontWeight: 500, color: "#000000" }}>{currentQuantity}</Typography>
      </Button>
      <IconButton
        sx={{
          width: 40,
          height: 40,
        }}
        aria-label="subtract"
        color="primary"
        onClick={handleIncrement}
        disabled={disabledIncrement}
      >
        <AddOutlinedIcon style={{ fill: iconIncrementColor }} />
      </IconButton>
    </ButtonGroup>
  );
};

const BjeQuantityCounter: React.FC<IButtonProps> = ({
  disabledDecrement,
  disabledIncrement,
  iconDecrementColor,
  iconIncrementColor,
  buttonDisabledColor,
  currentQuantity,
  handleDecrement,
  handleIncrement,
}) => {
  return (
    <ButtonGroup>
      <IconButton
        color="primary"
        onClick={handleDecrement}
        disabled={disabledDecrement}
      >
        <RemoveOutlinedIcon
          style={{
            fill: iconDecrementColor,
          }}
        />
      </IconButton>
      <Button
        sx={{
          border: "1px solid #c4c4c4",
          borderRadius: 4,
        }}
        disabled
        className={`designSummaryTable_cell_quantity_bje${buttonDisabledColor}`}
        variant="text"
      >
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 400,
            color: "#000",
          }}
        >
          {currentQuantity}
        </Typography>
      </Button>
      <IconButton
        aria-label="subtract"
        color="primary"
        onClick={handleIncrement}
        disabled={disabledIncrement}
      >
        <AddOutlinedIcon style={{ fill: iconIncrementColor }} />
      </IconButton>
    </ButtonGroup>
  );
};

const QuantityCounterButtonGroup: React.FC<IButtonProps> = ({
  disabledDecrement,
  disabledIncrement,
  iconDecrementColor,
  iconIncrementColor,
  buttonDisabledColor,
  currentQuantity,
  handleDecrement,
  handleIncrement,
}) => {
  const theme = useTheme() as FTSTheme;

  return (
    <>
      {(theme.name == ABB && (
        <AbbQuantityCounter
          disabledDecrement={disabledDecrement}
          disabledIncrement={disabledIncrement}
          iconDecrementColor={iconDecrementColor}
          iconIncrementColor={iconIncrementColor}
          buttonDisabledColor={buttonDisabledColor}
          currentQuantity={currentQuantity}
          handleDecrement={handleDecrement}
          handleIncrement={handleIncrement}
        />
      )) ||
        (theme.name == BJE && (
          <BjeQuantityCounter
            disabledDecrement={disabledDecrement}
            disabledIncrement={disabledIncrement}
            iconDecrementColor={iconDecrementColor}
            iconIncrementColor={iconIncrementColor}
            buttonDisabledColor={buttonDisabledColor}
            currentQuantity={currentQuantity}
            handleDecrement={handleDecrement}
            handleIncrement={handleIncrement}
          />
        ))}
    </>
  );
};

export default QuantityCounterButtonGroup;
