import { Action, action, thunk, Thunk } from "easy-peasy";
import { ILanguage } from "../interfaces/ILanguage";
import { Status } from "./status";
import languageService from "../services/LanguageService";
import logging from "../config/logging";

export interface ILanguageModel {
  languages: ILanguage[];
  setLanguages: Action<ILanguageModel, ILanguage[]>;
  thunkGetLanguages: Thunk<ILanguageModel, undefined>;
}

const languages: ILanguageModel = {
  languages: [],
  setLanguages: action((state, payload) => {
    state.languages = payload;
  }),
  thunkGetLanguages: thunk(async (actions, payload, { getState, getStoreState }) => {
    try {
      if (getState().languages.length == 0) {
        const languages = await languageService.get();
        actions.setLanguages(languages);
      }

      return Status.COMPLETED;
    } catch (err) {
      logging.error(err);
      return Status.FAILED;
    }
  }),
};

export default languages;
