import { useTheme } from "@mui/material";
import { ILayoutDimensions } from "../../../../interfaces/IDesignDimensions";

export interface IDimensionLine {
  show: boolean;
  dimensions: IDimension;
  actualDimensions?: ILayoutDimensions;
}

export interface IDimension {
  xpx: number;
  ypx: number;
  xmm: number;
  ymm: number;
  widthpx: number;
  heightpx: number;
  widthmm: number;
  heightmm: number;
  startPositionXMm: number;
  startPositionYMm: number;
  endPositionXMm: number;
  endPositionYMm: number;
}

const DimensionLine: React.FC<IDimensionLine> = (props) => {
  const theme = useTheme();

  const formatValue = (value: number) => {
    return (Math.round(value * 100) / 100).toFixed(2);
  };

  const showDimensionsX = props.actualDimensions?.posX ? props.actualDimensions.posX : props.dimensions.xmm;
  const showDimensionsY = props.actualDimensions?.posY ? props.actualDimensions.posY : props.dimensions.ymm;

  return (
    <>
      {props.show && (
        <>
          <div
            style={{
              width: `${props.dimensions.xpx!}px`,
              borderTop: `1px dashed ${theme.palette.primary.main}`,
              position: "absolute",
              left: `${(props.dimensions.xpx! - props.dimensions.widthpx! / 2) * -1}px`,
              top: `${props.dimensions.heightpx! / 2}px`,
              zIndex: 999,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0px",
                color: "white",
                fontSize: "10px",
                left: `-50px`,
                backgroundColor: `${theme.palette.primary.main}`,
                padding: "2px",
                width: "50px",
                textAlign: "center",
              }}
            >
              {formatValue(showDimensionsX)}mm
            </div>
          </div>

          <div
            style={{
              width: `${props.dimensions.ypx!}px`,
              borderTop: `1px dashed ${theme.palette.primary.main}`,
              position: "absolute",
              left: `${props.dimensions.widthpx! / 2 - props.dimensions.ypx! / 2}px`,
              top: `${props.dimensions.ypx! / 2 + props.dimensions.heightpx! / 2}px`,
              transform: "rotate(270deg)",
              zIndex: 999,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0px",
                color: "white",
                fontSize: "10px",
                left: `-50px`,
                backgroundColor: `${theme.palette.primary.main}`,
                padding: "2px",
                width: "50px",
                textAlign: "center",
              }}
            >
              {formatValue(showDimensionsY)}mm
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DimensionLine;
