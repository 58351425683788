import { Grid } from "@mui/material";
import TileConfiguration from "../TileConfiguration";
import StandardTile from "../StandardTile";
import { ITileButtonProps } from "../../../../../interfaces/ITileButtonProps";
import TilePreviewConfiguration from "../TilePreviewConfiguration";
import { ITileDimension } from "../../../../../interfaces/ITileDimension";
import { EFontPixelSize } from "../../../../../enum/EFontSize";
import { EIconPercentSize, EIconPixelSize } from "../../../../../enum/EIconSize";
import { isHorizontal, LimitChar } from "../limitChar";
import GridLine from "../../../Components/GridLine/GridLine";
import { useRef } from "react";
import { EInsertType } from "../../../../../enum/EInsertType";
import { EInsertDetailItemType } from "../../../../../interfaces/IInsertDetail";
import SliderLine from "../../../Components/SliderLine/SliderLine";
import { FrameStyleProps } from "../../../../../interfaces/IFrameAndCoverStyle";

const TwoSliders: React.FC<ITileButtonProps> = (props) => {
  const border = props.isPreview ? "tile-border-preview" : "tile-border";

  const tileDimension: ITileDimension = {
    iconPixelDefault: EIconPixelSize.DEFAULT_CONTROL,
    iconSizeDefault: EIconPercentSize.MEDIUM,
    iconMinPercentage: EIconPercentSize.SMALL,
    iconMaxPercentage: EIconPercentSize.XLARGE,
    fontSizeDefault: EFontPixelSize.STANDARD,
    fontSizeMin: EFontPixelSize.STANDARD,
    fontSizeMax: EFontPixelSize.MEDIUM,
    textareaMaxHeight: 42,
    spaceBetweenIconAndTextArea: 4,
    isFunction: false,
  };

  const textFieldProps = LimitChar.get(props.designCode)!;
  const coverRef = useRef<HTMLDivElement>(null);
  const slider1TopRef = useRef<HTMLHRElement>(null);
  const slider1BottomRef = useRef<HTMLHRElement>(null);

  const slider2TopRef = useRef<HTMLHRElement>(null);
  const slider2BottomRef = useRef<HTMLHRElement>(null);

  return (
    <>
      <div
        ref={coverRef}
        data-designcode={props.designCode}
      >
        <StandardTile
          designCode={props.designCode}
          direction="column"
        >
          <Grid
            className={`${border} container direction-column two-sliders-margin-right ${
              props.insertDetail.isFlipped ? "isFlipped" : ""
            }`}
            container
            item
            justifyContent="center"
            alignItems="center"
          >
            <SliderLine
              coverRef={coverRef}
              elementRef={slider2TopRef}
              className={`slider slider-top`}
              indexDetail="5"
              cover={props.cover}
              insertDetail={props.insertDetail}
              setInsertDetail={props.setInsertDetail}
            />
            {props.isPreview ? (
              <span>
                <TilePreviewConfiguration
                  tileDimension={tileDimension}
                  designCode={props.designCode}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.twoSliders
                      : textFieldProps.vertical.twoSliders
                  }
                  tileName="twoSliders"
                  isRocker={false}
                  cover={props.cover}
                  insertDetail={props.insertDetail!}
                  indexDetail="2"
                />
              </span>
            ) : (
              <span>
                <TileConfiguration
                  isRocker={false}
                  tileType={EInsertType.Slider}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.twoSliders
                      : textFieldProps.vertical.twoSliders
                  }
                  tileName="twoSliders"
                  isHorizontal={props.isHorizontal}
                  designCode={props.designCode}
                  tileDimension={tileDimension}
                  insertDetail={props.insertDetail}
                  setInsertDetail={props.setInsertDetail}
                  cover={props.cover}
                  indexDetail="2"
                  coverRef={coverRef}
                  canManageRef={props.canManageRef}
                />
              </span>
            )}
            <SliderLine
              coverRef={coverRef}
              elementRef={slider2BottomRef}
              className={`slider slider-bottom${
                props.insertDetail.items?.find(
                  (item) => item.type === EInsertDetailItemType.Text && item.indexDetail == "2"
                )
                  ? "-with-canvas"
                  : ""
              }`}
              indexDetail="6"
              cover={props.cover}
              insertDetail={props.insertDetail}
              setInsertDetail={props.setInsertDetail}
            />
          </Grid>
          <GridLine
            coverRef={coverRef}
            styleProps={{
              width: "1px",
              backgroundColor: props.insertDetail.insertColor?.secondaryPrintColor?.hexColor,
              position: "absolute",
              left: "calc(50% - 0.5px)",
              height: `calc(100% - ${FrameStyleProps.get(props.designCode)?.cover.padding} * 2)`,
            }}
            enable={props.insertDetail.hasGridLine}
            cover={props.cover}
            indexDetail="7"
            insertDetail={props.insertDetail}
            setInsertDetail={props.setInsertDetail}
            verticalDegrees={[0, 180]}
            defaultOrientation="vertical"
          />
          <Grid
            className={`${border} container direction-column two-sliders-margin-left`}
            container
            item
            justifyContent="center"
            alignItems="center"
          >
            <SliderLine
              coverRef={coverRef}
              elementRef={slider1TopRef}
              className={`slider slider-top`}
              indexDetail="3"
              cover={props.cover}
              insertDetail={props.insertDetail}
              setInsertDetail={props.setInsertDetail}
            />
            {props.isPreview ? (
              <span>
                <TilePreviewConfiguration
                  tileDimension={tileDimension}
                  designCode={props.designCode}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.twoSliders
                      : textFieldProps.vertical.twoSliders
                  }
                  tileName="twoSliders"
                  isRocker={false}
                  cover={props.cover}
                  insertDetail={props.insertDetail!}
                  indexDetail="1"
                />
              </span>
            ) : (
              <span>
                <TileConfiguration
                  isRocker={false}
                  tileType={EInsertType.Slider}
                  textLabelSizes={
                    isHorizontal(props.insertDetail.rotate!)
                      ? textFieldProps.horizontal.twoSliders
                      : textFieldProps.vertical.twoSliders
                  }
                  tileName="twoSliders"
                  isHorizontal={props.isHorizontal}
                  designCode={props.designCode}
                  tileDimension={tileDimension}
                  insertDetail={props.insertDetail}
                  setInsertDetail={props.setInsertDetail}
                  cover={props.cover}
                  indexDetail="1"
                  coverRef={coverRef}
                  canManageRef={props.canManageRef}
                />
              </span>
            )}
            <SliderLine
              coverRef={coverRef}
              elementRef={slider1BottomRef}
              className={`slider slider-bottom${
                props.insertDetail.items?.find(
                  (item) => item.type === EInsertDetailItemType.Text && item.indexDetail == "1"
                )
                  ? "-with-canvas"
                  : ""
              }`}
              indexDetail="4"
              cover={props.cover}
              insertDetail={props.insertDetail}
              setInsertDetail={props.setInsertDetail}
            />
          </Grid>
        </StandardTile>
      </div>
    </>
  );
};

export default TwoSliders;
