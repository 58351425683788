import { Grid, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { createRef, forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStoreActions, useStoreState } from "../../../../../hooks";
import { IDialogHandle } from "../../../../../interfaces/IDialogHandle";
import { IProjectCreateRequest } from "../../../../../interfaces/Project/Request/IProjectCreateRequest";
import { Status } from "../../../../../models/status";
import "./ConfiguratorWelcomeDialog.css";
import ConfirmButton from "./ConfirmButton";
import BackButton from "./BackButton";
import { ECompany } from "../../../../../enum/ECompany";
import { useNavigate } from "react-router-dom";
import { BJE } from "../../../../../constants/Theme";
import { FAVORITE_DESIGNS_PATH } from "../../../../../constants/Paths";

interface IConfiguratorWelcomeDialogInputProps {
  favoriteDesignId?: string | null;
}

export const configuratorWelcomeDialogRef = createRef<IDialogHandle>();

const ConfiguratorWelcomeDialog: ForwardRefRenderFunction<IDialogHandle, IConfiguratorWelcomeDialogInputProps> = (
  { favoriteDesignId },
  ref
) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentPath = window.location.pathname;

  const [projectName, setProjectName] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useStoreState((state) => state.auth);

  const { thunkOpenSnackBar } = useStoreActions((actions) => actions.app);
  const { thunkCreateProject, thunkCreateProjectFromFav } = useStoreActions((actions) => actions.designs);

  const isFavoriteDesignPage = currentPath === FAVORITE_DESIGNS_PATH;

  const handleCreateProject = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setLoading(true);
    const name = projectName.trim();

    if (name.length > 0 && name.length < 51) {
      let resProject;

      if (favoriteDesignId) {
        resProject = await thunkCreateProjectFromFav({
          projectName: name,
          company: process.env.REACT_APP_THEME == BJE ? ECompany.BJE : ECompany.ABB,
          favoriteDesignId,
        });
      } else {
        const payloadProject: IProjectCreateRequest = {
          projectName: name,
          company: process.env.REACT_APP_THEME == BJE ? ECompany.BJE : ECompany.ABB,
        };

        resProject = await thunkCreateProject(payloadProject);
      }

      if (resProject.status == Status.FAILED) {
        setLoading(false);

        thunkOpenSnackBar({
          message: t(resProject.message),
          severity: resProject.message == "CreateProject_AlreadyExists_Message" ? "warning" : "error",
        });
        return;
      }

      setLoading(false);

      thunkOpenSnackBar({
        message: t("configurator_welcome_dialog.create_project_response.success_message"),
        severity: "success",
      });

      handleClose();
    } else {
      thunkOpenSnackBar({
        message: t("configurator_welcome_dialog.create_project_response.alert_message"),
        severity: "warning",
      });
      return;
    }

    setLoading(false);
    handleClose();

    if (favoriteDesignId) {
      navigate("/insert-configuration", {
        state: { isNewProject: true, fromFavoriteDesign: true },
      });
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => {
    return {
      open: handleOpen,
      close: handleClose,
    };
  });

  return (
    <>
      <Dialog
        PaperProps={{
          sx: { position: "fixed", top: "17%", m: 0 },
        }}
        fullWidth={true}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "480px",
            },
          },
        }}
        open={open}
      >
        <DialogContent>
          <div className="dialogcontent__main">
            <div className="dialogcontent__body">
              <Typography
                sx={{
                  fontSize: "21px",
                  fontWeight: 700,
                }}
              >
                {t("configurator_welcome_dialog.label.new_project")}
              </Typography>
              <Typography
                variant="body1"
                mt={1}
              >
                {t("configurator_welcome_dialog.label.create_new_name_project")}
              </Typography>
              <div className="body__formControlContainer">
                <FormControl className="body__formControl">
                  <Typography variant="caption">{t("configurator_welcome_dialog.label.project_name")}</Typography>
                  <TextField
                    disabled={loading}
                    id="projectName"
                    inputProps={{
                      style: {
                        padding: 5,
                      },
                    }}
                    sx={{
                      margin: "20px 0 10px 0",
                      width: "100%",
                    }}
                    value={projectName}
                    onChange={(e) => {
                      if (!/[\uD800-\uDBFF][\uDC00-\uDFFF]/g.test(e.target.value)) setProjectName(e.target.value);
                    }}
                    helperText={
                      projectName.trim().length > 50 ? t("configurator_welcome_dialog.helper_text.project_name") : ""
                    }
                    error={projectName.trim().length > 50 ? true : false}
                  />
                </FormControl>
              </div>
            </div>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
            >
              <BackButton
                loading={loading}
                isFavoriteDesignPage={isFavoriteDesignPage}
                handleClose={handleClose}
              />
              <ConfirmButton
                loading={loading}
                disabled={loading || projectName.trim().length > 50 || projectName.trim().length === 0}
                handleCreateProject={handleCreateProject}
              />
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default forwardRef(ConfiguratorWelcomeDialog);
