import { FC } from "react";
import "./Demo.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography, useTheme } from "@mui/material";
import StartConfigurationButton from "../../pages/Dashboard/StartConfigurationButton";
import { FTSTheme } from "../../App";
import { useStoreState } from "../../hooks";
import { COUNTRIES_CODES } from "../../constants/Languages";
import { ABB } from "../../constants/Theme";

const Demo: FC<{}> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { currentUser } = useStoreState((state) => state.auth);
  const isSwitzerland = currentUser?.countryId === COUNTRIES_CODES.Switzerland;
  const themeName = (theme as any as FTSTheme).name;
  const headerText = t(
    themeName === ABB && isSwitzerland ? "abb.customization_center.switzerland" : `${themeName}.dashboard.app_title`
  );
  const mainText = t(
    isSwitzerland ? "createYourOwnABBSIDUS" : "createYourOwnMultifunctionSwitchesWithTouchSensorCover"
  ).split(" ");
  const text1 = mainText.slice(0, 5).join(" ");
  const text2 = mainText.slice(5, mainText.length).join(" ");

  return (
    <div className="demo">
      {isSwitzerland ? (
        <div
          className="demo__imgContainer"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_BLOB_STORAGE_URL}/images/app-images/fts-banner-swiss.png)`,
          }}
        ></div>
      ) : (
        <div
          className="demo__imgContainer"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_BLOB_STORAGE_URL}/images/app-images/fts-banner.png)`,
          }}
        ></div>
      )}
      <div className="demo__textContainer">
        <Typography
          variant="h2"
          sx={{
            marginBlockEnd: "0",
            marginBlockStart: "0",
          }}
        >
          {headerText}
        </Typography>
        <div
          className="demo__textDivider"
          style={{
            backgroundColor: theme.palette.primary.main,
          }}
        ></div>
        <div className="demo__create">
          <div className="demo__createTextContainer">
            <Typography
              variant="body1"
              className="demo__createText"
            >
              <span>{text1}</span>
              <br />
              <span>{text2}</span>
            </Typography>
          </div>
          <Link
            className="demo__createBtn"
            to="/welcome-to-frame-touch-sensor-configurator"
          >
            <StartConfigurationButton />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Demo;
