import { Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../App";

interface INavbarTitleProps {
  title: string;
}

export function NavbarTitle({ title }: INavbarTitleProps) {
  const { t } = useTranslation();
  const theme = useTheme() as FTSTheme;
  return (
    <div className="navbar__title">
      <Typography variant="h1">{t(`${theme.name}.${title}`)}</Typography>
    </div>
  );
}
