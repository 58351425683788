import { AxiosResponse } from "axios";
import axiosInstance from "../config/axios";
import { IInsertType } from "../interfaces/IInsertType";

const responseBody = (response: AxiosResponse) => response;

const requests = {
  get: (url: string, options?: {}) => axiosInstance.get(url, options).then(responseBody),
};

const InsertTypeService = {
  getAll: (): Promise<AxiosResponse<IInsertType[]>> => requests.get(`inserttype`),
  getById: (id: string): Promise<AxiosResponse<IInsertType>> => requests.get(`inserttype/${id}`),
};

export default InsertTypeService;
