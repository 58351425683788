import { Action, Thunk, action, thunk } from "easy-peasy";
import { ICountry } from "../interfaces/ICountry";
import CountryService from "../services/CountryService";
import { Status } from "./status";
import logging from "../config/logging";
export interface ICountryModel {
  countries: ICountry[];
  setCountries: Action<ICountryModel, ICountry[]>;
  thunkGetCountries: Thunk<ICountryModel, undefined>;
}

const countries: ICountryModel = {
  countries: [],
  setCountries: action((state, payload) => {
    state.countries = payload;
  }),
  thunkGetCountries: thunk(async (actions, payload, { getState, getStoreState }) => {
    try {
      if (getState().countries.length == 0) {
        const countries = await CountryService.get();
        actions.setCountries(countries);
      }

      return Status.COMPLETED;
    } catch (err) {
      logging.error(err);
      return Status.FAILED;
    }
  }),
};

export default countries;
