import axiosAuthInstance from "../config/axiosAuth";

const SSOService = {
  getAccessToken: (code: string) => {
    return axiosAuthInstance.post(
      "/sso/token",
      new URLSearchParams({
        grant_type: "authorization_code",
        code: code,
        client_id: process.env.REACT_APP_CLIENT_ID!,
        client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET!,
        redirect_uri: process.env.REACT_APP_REDIRECT_URI!,
      })
    );
  },
  refreshToken: () => {
    return axiosAuthInstance.post(
      "/sso/token",
      new URLSearchParams({
        grant_type: "refresh_token",
        client_id: process.env.REACT_APP_CLIENT_ID!,
        client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET!,
        refresh_token: localStorage.getItem("[FTS_Customisation_Center][refresh_token]") as string,
      })
    );
  },
  getUserData: () => {
    const access_token = localStorage.getItem("[FTS_Customisation_Center][access_token]") as string;
    return axiosAuthInstance.get("/api/user", {
      headers: { Authorization: `Bearer ${access_token}` },
    });
  },
};

export default SSOService;
