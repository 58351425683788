import Box from "@mui/system/Box";
import { FC, useEffect, useState } from "react";
import { useStoreActions } from "../../../../hooks";
import { IDesign } from "../../../../interfaces/IDesign";
import "./ProductGrid.css";
import { Typography } from "@mui/material";

interface IProductGridProps {
  designs: IDesign[];
}

const ProductGrid: FC<IProductGridProps> = ({ designs }) => {
  const [selectedDesignId, setSelectedDesignId] = useState<string>("");

  const { setUserDesignData, setUserDesignDataFromDesign } = useStoreActions((actions) => actions.designs);
  const FALLBACK_IMAGE = "./assets/images/image-not-found.png";

  useEffect(() => {
    const initialDesign = designs[0];
    setSelectedDesignId(initialDesign.id);
    setUserDesignDataFromDesign(initialDesign);
  }, [designs, setUserDesignData]);

  const handleSelectItem = (design: IDesign) => {
    setUserDesignDataFromDesign(design);
    setSelectedDesignId(design.id);
  };

  const addDefaultImageOnError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = FALLBACK_IMAGE;
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(5, 1fr)",
        gap: "16px",
        justifyItems: "center",
        alignItems: "center",
        minHeight: "450px",
      }}
    >
      {designs.map((design, index) => (
        <Box
          key={index}
          onClick={() => handleSelectItem(design)}
          className={selectedDesignId === design.id ? "gridView__item gridView__selectedItem" : "gridView__item"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <img
            src={design.designRangeUrl}
            alt={design.code}
            style={{ width: "100%", minWidth: "140px" }}
            onError={addDefaultImageOnError}
          />
          <Typography
            variant="body1"
            fontSize="16px"
            fontWeight={300}
            color="#000000"
            textAlign="center"
          >
            {design.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ProductGrid;
