import Button from "@mui/material/Button";
import { FC, useEffect } from "react";
import ExternalLayout from "../../components/Layout/ExternalLayout";
import "./Checkout.css";
import CheckoutDialog, { checkoutDialogRef } from "./CheckoutDialog";
import { useTranslation } from "react-i18next";
import useAppTitle from "../../hooks/useAppTitle";

const Checkout: FC<{}> = (props) => {
  const { t } = useTranslation();
  const appTitle = useAppTitle();
  useEffect(() => {
    checkoutDialogRef.current?.open();
  }, []);

  return (
    <ExternalLayout
      showNavbarCenteredText={false}
      showNavbarLinks={true}
      showPageHeader={false}
      pageHeaderTextTranslationKey="welcomeToBuschJaegerCustomisationCenter"
      navbarLogoLink="/"
      navbarMainTitle={appTitle}
      navbarBackGroundColor="#fff"
      bodyBackGroundColor="#fff"
      customPageHeader="checkout.page_header_title"
    >
      <div className="checkout">
        <Button
          onClick={() => {
            checkoutDialogRef.current?.open();
          }}
        >
          {t("checkout.button.open_dialog")}
        </Button>

        <CheckoutDialog ref={checkoutDialogRef} />
      </div>
    </ExternalLayout>
  );
};

export default Checkout;
