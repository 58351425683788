import React from "react";
import { useEffect, useState } from "react";
import { useStoreState } from "../../hooks";
import { Button, Grid, Typography } from "@mui/material";
import { handleError } from "../../utils/handleError";
import ExternalLayout from "../../components/Layout/ExternalLayout";
import DOMPurify from "dompurify";
import PrivacyNoticeService from "../../services/PrivacyNoticeService";
import "./PrivacyNotice.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useTranslation } from "react-i18next";
import { MainLayout } from "../../components/Layout";
import { NavbarNew } from "../../components/Navbar";
import ProfileImg from "../../images/Profile.png";
import BackButton from "../Profile/BackButton";
import useAppTitle from "../../hooks/useAppTitle";

const pdfRef = React.createRef<HTMLDivElement>();

const PrivacyNotice: React.FC = () => {
  const { t } = useTranslation();
  const currentUser = useStoreState((state) => state.auth.currentUser);
  const languages = useStoreState((state) => state.languages.languages);
  const [content, setContent] = useState<string>("");
  const appTitle = useAppTitle();
  const cleanHTML = DOMPurify.sanitize(content, { USE_PROFILES: { html: true } });

  useEffect(() => {
    const currentLanguageCode = localStorage.getItem("lang");
    let languageId = languages.find((x) => x.code == currentLanguageCode)?.id!;

    if (!languageId) languageId = currentUser?.languageId!;

    PrivacyNoticeService.get(languageId)
      .then((response) => {
        setContent(response.data.content);
      })
      .catch((err) => {
        handleError(err);
      });
  }, []);

  const handleDownloadPDF = () => {
    const input = pdfRef.current!;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 5;
      pdf.addImage(imgData, "PNG", imgX, imgY, imgWidth * ratio, imgHeight * ratio);
      pdf.save("privacy-notice.pdf");
    });
  };

  return (
    <ExternalLayout
      showNavbarCenteredText={false}
      showNavbarLinks={true}
      showPageHeader={false}
      navbarLogoLink="/"
      navbarMainTitle={appTitle}
      navbarBackGroundColor="#fafafa"
      bodyBackGroundColor="#fafafa"
      customPageHeaderOffSection={
        <div
          className="ProfileImg"
          style={{
            width: "100%",
            backgroundImage: `url(${ProfileImg})`,
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      }
    >
      <Grid
        container
        justifyContent={"space-between"}
        mb={2}
      >
        <BackButton />
        <Button
          sx={{
            marginTop: "15px",
          }}
          variant="contained"
          color="primary"
          onClick={handleDownloadPDF}
        >
          {t("privacy_notice.button.download_pdf")}
        </Button>
      </Grid>
      <div
        style={{
          maxHeight: "calc(100vh - 380px)",
          overflow: "auto",
        }}
      >
        <div
          ref={pdfRef}
          className="privacy-notice"
        >
          <Typography>
            <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />
          </Typography>
        </div>
      </div>
    </ExternalLayout>

    // <MainLayout.Root bodyBackgroundColor="#fafafa">
    //   <NavbarNew.Root>
    //     <div className="navbar__logoTitleContainer">
    //       <NavbarNew.Logo link="/" />
    //       <NavbarNew.Title title={`navbar_main_title`} />
    //     </div>
    //     <NavbarNew.Links>
    //       <NavbarNew.Link
    //         translationKey="dashboard"
    //         path="/dashboard"
    //       />
    //       <NavbarNew.Link
    //         translationKey="myProjects"
    //         path="/projects"
    //       />
    //       <div className="link__separator"></div>
    //       {/* <Link
    //         to="/checkout"
    //         className="shoppingCart__link"
    //       >
    //         <ShoppingCartIcon
    //           width={24}
    //           height={24}
    //         />
    //         <Typography variant="body1">{t("cart")}</Typography>
    //       </Link> */}
    //       <NavbarNew.User />
    //     </NavbarNew.Links>
    //   </NavbarNew.Root>
    //   <MainLayout.Content>
    //   <div
    //       className="ProfileImg"
    //       style={{
    //         width: "100%",
    //         backgroundImage: `url(${ProfileImg})`,
    //         backgroundRepeat: "no-repeat",
    //       }}
    //     ></div>

    //     <Grid
    //       container
    //       justifyContent={"flex-end"}
    //     >
    //       <Button
    //         sx={{
    //           marginTop: "15px",
    //         }}
    //         variant="contained"
    //         color="primary"
    //         onClick={handleDownloadPDF}
    //       >
    //         {t("privacy_notice.button.download_pdf")}
    //       </Button>
    //     </Grid>
    //     <div
    //       ref={pdfRef}
    //       className="privacy-notice"
    //     >
    //       <Typography>
    //         <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />
    //       </Typography>
    //     </div>
    //   </MainLayout.Content>
    // </MainLayout.Root>
  );
};

export default PrivacyNotice;
