import { useTheme } from "@emotion/react";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FTSTheme } from "../../../App";
import { ReactComponent as ArrowRight } from "../../../Icons/abb-arrow-right.svg";
import FitText from "../../../components/FitText/FitText";
import { ABB } from "../../../constants/Theme";

interface SeeAllButtonProps {
  pathname: string;
}

const AbbSeeAllButton: React.FC<SeeAllButtonProps> = ({ pathname }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <Button
        variant="text"
        onClick={() => navigate(pathname)}
        endIcon={<ArrowRight />}
      >
        <FitText
          sx={{
            fontWeight: 500,
            fontSize: "16px",
          }}
        >
          {t("orderShowAll")}
        </FitText>
      </Button>
    </>
  );
};

const BjeSeeAllButton: React.FC<SeeAllButtonProps> = ({ pathname }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <Button
        variant="text"
        onClick={() => navigate(pathname)}
        endIcon={<ChevronRightOutlinedIcon fontSize="small" />}
      >
        <FitText>{t("orderShowAll")}</FitText>
      </Button>
    </>
  );
};

const SeeAllButton: React.FC<SeeAllButtonProps> = (rest) => {
  const ftsTheme = useTheme() as FTSTheme;
  return <>{ftsTheme.name == ABB ? <AbbSeeAllButton {...rest} /> : <BjeSeeAllButton {...rest} />}</>;
};

export default SeeAllButton;
