import { useEffect, useState } from "react";
import ExternalLayout from "../../components/Layout/ExternalLayout";
import "./TermsAndConditionPage.css";
import TermsAndConditionsService from "../../services/TermsAndConditionsService";
import { useStoreState } from "../../hooks";
import DOMPurify from "dompurify";
import { Grid, Typography } from "@mui/material";
import { handleError } from "../../utils/handleError";
import ProfileImg from "../../images/Profile.png";
import BackButton from "../Profile/BackButton";
import useAppTitle from "../../hooks/useAppTitle";

const TermsAndConditions: React.FC = () => {
  const currentUser = useStoreState((state) => state.auth.currentUser);
  const languages = useStoreState((state) => state.languages.languages);
  const [content, setContent] = useState<string>("");
  const appTitle = useAppTitle();
  const cleanHTML = DOMPurify.sanitize(content, { USE_PROFILES: { html: true } });

  useEffect(() => {
    const currentLanguageCode = localStorage.getItem("lang");
    let languageId = languages.find((x) => x.code == currentLanguageCode)?.id!;

    if (!languageId) languageId = currentUser?.languageId!;

    TermsAndConditionsService.get(languageId)
      .then((response) => {
        setContent(response.data.content);
      })
      .catch((err) => {
        handleError(err);
      });
  }, []);

  return (
    <ExternalLayout
      showNavbarCenteredText={false}
      showNavbarLinks={true}
      showPageHeader={false}
      navbarLogoLink="/"
      navbarMainTitle={appTitle}
      navbarBackGroundColor="#fafafa"
      bodyBackGroundColor="#fafafa"
      customPageHeaderOffSection={
        <div
          className="ProfileImg"
          style={{
            width: "100%",
            backgroundImage: `url(${ProfileImg})`,
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      }
    >
      <BackButton />
      <div
        style={{
          maxHeight: "calc(100vh - 380px)",
          overflow: "auto",
          marginTop: "10px",
        }}
      >
        <Grid
          columns={{ xs: 12, md: 12, xl: 12, sm: 12 }}
          container
          className="terms-and-conditions"
        >
          <Typography>
            <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />
          </Typography>
        </Grid>
      </div>
    </ExternalLayout>
  );
};

export default TermsAndConditions;
