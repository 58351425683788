import { IRoute } from "../interfaces/IRoute";
import BrowserNotSupported from "../pages/BrowserNotSupported/BrowserNotSupported";
import Checkout from "../pages/Checkout/Checkout";
import ConfiguratorStart from "../pages/Configurator/ConfiguratorStart";
import FrameConfiguration from "../pages/Configurator/FrameConfiguration/FrameConfiguration";
import ConfiguratorWelcome from "../pages/Configurator/Components/WelcomePage/ConfiguratorWelcome";
import Dashboard from "../pages/Dashboard/Dashboard";
import Login from "../pages/Login/Login";
import NotFound from "../pages/NotFound/NotFound";
import ProductCustomisation from "../pages/ProductCustomisation/ProductCustomisation";
import Projects from "../pages/Projects/Projects";
import FavoriteDesigns from "../pages/FavoriteDesigns/FavoriteDesigns";
import ProtectedRoute from "./ProtectedRoute";
import InsertConfiguration from "../pages/Configurator/InsertConfiguration/InsertConfiguration";
import DesignPreview from "../pages/Configurator/DesignPreview/DesignPreview";
import DesignSummary from "../pages/Configurator/DesignSummary/DesignSummary";
import TermsAndConditions from "../pages/TermsAndCondition/TermsAndCondition";
import SessionExpired from "../pages/SessionExpired/SessionExpired";
import Profile from "../pages/Profile/Profile";
import PrivacyNotice from "../pages/PrivacyNotice/PrivacyNotice";
import ProjectSummary from "../pages/Configurator/ProjectSummary/ProjectSummary";

const appRoutes: IRoute[] = [
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "/welcome-to-frame-touch-sensor-configurator",
    element: (
      <ProtectedRoute>
        <ConfiguratorWelcome />
      </ProtectedRoute>
    ),
  },
  {
    path: "/start-configurator",
    element: (
      <ProtectedRoute>
        <ConfiguratorStart />
      </ProtectedRoute>
    ),
  },
  {
    path: "/frame-configuration",
    element: (
      <ProtectedRoute>
        <FrameConfiguration />
      </ProtectedRoute>
    ),
  },
  {
    path: "/insert-configuration",
    element: (
      <ProtectedRoute>
        <InsertConfiguration />
      </ProtectedRoute>
    ),
  },
  {
    path: "/design-preview",
    element: (
      <ProtectedRoute>
        <DesignPreview />
      </ProtectedRoute>
    ),
  },
  {
    path: "/design-summary",
    element: (
      <ProtectedRoute>
        <DesignSummary />
      </ProtectedRoute>
    ),
  },
  {
    path: "/projects-summary",
    element: (
      <ProtectedRoute>
        <ProjectSummary />
      </ProtectedRoute>
    ),
  },
  {
    path: "/product-customisation",
    element: (
      <ProtectedRoute>
        <ProductCustomisation />
      </ProtectedRoute>
    ),
  },
  {
    path: "/projects",
    element: (
      <ProtectedRoute>
        <Projects />
      </ProtectedRoute>
    ),
  },
  {
    path: "/checkout",
    element: (
      <ProtectedRoute>
        <Checkout />
      </ProtectedRoute>
    ),
  },
  {
    path: "/terms-and-conditions",
    element: (
      <ProtectedRoute>
        <TermsAndConditions />
      </ProtectedRoute>
    ),
  },
  {
    path: "/privacy-notice",
    element: (
      <ProtectedRoute>
        <PrivacyNotice />
      </ProtectedRoute>
    ),
  },
  {
    path: "/favoriteDesigns",
    element: (
      <ProtectedRoute>
        <FavoriteDesigns />
      </ProtectedRoute>
    ),
  },
  {
    path: "/profile",
    element: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    ),
  },
  {
    path: "/session-expired",
    element: <SessionExpired />,
  },
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/not-supported",
    element: <BrowserNotSupported />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default appRoutes;
