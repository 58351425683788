import { IInsertColor } from "./IInsertColor";
import { IInsertType } from "./IInsertType";

export interface IInsertDetail {
  favoriteDesignId?: string | undefined;
  id?: string;
  insertType?: IInsertType;
  insertColor?: IInsertColor;
  order?: EInsertDetailOrder;
  coverDesignId?: string;
  rotate?: number;
  hasGridLine?: boolean;
  isFlipped?: boolean;
  items: IInsertDetailItem[];
}

export interface IInsertDetailItem {
  id?: string;
  insertDetailId?: string;
  type: EInsertDetailItemType;
  indexDetail: string;
  color: string;
  iconId?: string;
  iconName?: string;
  materialNumber?: string;
  textValue?: string;
  lineColor?: string;
  lineGaps?: number;
  lineSpacing?: number;
  dimension?: IInsertDetailItemDimension;
  position?: IInsertDetailItemPosition;
  positionStart?: IInsertDetailItemPosition;
  positionEnd?: IInsertDetailItemPosition;
  font?: IInsertDetailItemFont;
  coverIndex: number;
  gridLine?: boolean;
}

export interface IInsertDetailItemDimension {
  width: number;
  height: number;
}

export class InsertDetailItemDimension implements IInsertDetailItemDimension {
  width: number;
  height: number;

  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }
}

export interface IInsertDetailItemPosition {
  x: number;
  y: number;
}

export class InsertDetailItemPosition implements IInsertDetailItemPosition {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}

export interface IInsertDetailItemFont {
  size: number;
  name: string;
  style: string;
  weight: string;
}

export class InsertDetailItemFont implements IInsertDetailItemFont {
  size: number;
  name: string;
  style: string;
  weight: string;

  constructor(size: number, name: string, style: string, weight: string) {
    this.size = size;
    this.name = name;
    this.style = style;
    this.weight = weight;
  }
}

export enum EInsertDetailItemType {
  Icon,
  Text,
  Slider,
  SeparationLine,
}

export interface IInsertDetailRequest {
  id?: string;
  insertTypeId?: string;
  insertColorId?: string;
  order?: EInsertDetailOrder;
  coverDesignId?: string;
  rotate?: number;
  hasGridLine?: boolean;
  isFlipped?: boolean;
  items: IInsertDetailItem[];
}

export enum EInsertDetailOrder {
  First = 1,
  Second = 2,
  Third = 3,
  Fourth = 4,
  Fifth = 5,
  Sixth = 6,
}
