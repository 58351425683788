import { FormControl, Autocomplete, TextField, Button, Typography } from "@mui/material";
import { ReactSVG } from "react-svg";
import { ICountry } from "../../../interfaces/ICountry";
import { ILanguage } from "../../../interfaces/ILanguage";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../auth/utils";
import { ILoggedUser } from "../../../interfaces/DTO/ILoggedUser";
import UserService from "../../../services/UserService";
import { countryDialogRef } from "./CountryDialog";
import { useStoreActions } from "../../../hooks";
import ArrowDownIcon from "../../../pages/Configurator/Icons/arrow-down.svg";
import LoadingSkeleton from "./Skeleton";
import { HttpStatusCode } from "axios";

interface IContentProps {
  currentUser: ILoggedUser;
  countries: ICountry[];
  languages: ILanguage[];
}

const Content: React.FC<IContentProps> = (props) => {
  const { currentUser, countries, languages } = props;

  const { t } = useTranslation();

  const { user } = useAuth();

  const { setLanguageCode, setCurrentUser } = useStoreActions((actions) => actions.auth);
  const { thunkOpenSnackBar } = useStoreActions((actions) => actions.app);

  const [countryValue, setCountryValue] = useState<ICountry | null>(null);
  const [languageValue, setLanguageValue] = useState<ILanguage | null>(null);

  useEffect(() => {
    if (countries.length > 0 && languages.length > 0 && currentUser != null) {
      const userCountry = countries.find((x) => x.id == currentUser?.countryId) ?? countries[0];
      const userLanguage = languages.find((x) => x.id == userCountry.defaultLanguage)!;

      setCountryValue(userCountry);
      setLanguageValue(userLanguage);
    }
  }, [countries, languages, currentUser]);

  const saveFormData = (e: React.FormEvent) => {
    const country = countries.find((x) => x.code == countryValue?.code)!;
    const countryId = country.id;
    const isElBridgeAvailable = country.isElBridgeAvailable;

    UserService.updateCountryAndLanguage(user?.email!, countryValue?.code!, languageValue?.id!)
      .then((response) => {})
      .catch((err) => {
        let message = t("profile_page.tab_settings.label.on_save_generic_error_message");
        if (err?.response?.status === HttpStatusCode.NotFound || err?.response?.status === HttpStatusCode.BadRequest) {
          message = t(err.response.data);
        }
        thunkOpenSnackBar({
          message: message,
          severity: `error`,
        });
      })
      .finally(() => {});

    const newUser: ILoggedUser = {
      email: currentUser.email,
      name: currentUser?.name!,
      countryId: countryId,
      isElBridgeAvailable: isElBridgeAvailable,
      languageId: languageValue?.id!,
      termsAndConditionsAccepted: currentUser.termsAndConditionsAccepted,
    };

    setCurrentUser(newUser);

    setLanguageCode(languages.find((x) => x.id == languageValue?.id)?.code!);

    countryDialogRef.current?.close();
  };

  const handleChangeCountry = (country: ICountry | null) => {
    setCountryValue(country);
    if (country) {
      const defaultLanguage = languages.find((x) => x.id == country.defaultLanguage)!;
      setLanguageValue(defaultLanguage);
    }
  };

  const handleChangeLanguage = (language: ILanguage | null) => {
    setLanguageValue(language);
  };

  return (
    <>
      <div>
        <FormControl
          sx={{ mb: "15px", width: "100%" }}
          size="small"
        >
          <Typography variant="caption">{t("Language_Dialog_Country")}</Typography>
          {countries.length == 0 && <LoadingSkeleton />}
          {countries.length > 0 && (
            <Autocomplete
              value={countryValue}
              defaultValue={countryValue}
              options={countries}
              getOptionLabel={(options: ICountry) => options.name}
              popupIcon={
                <i>
                  <ReactSVG src={ArrowDownIcon} />
                </i>
              }
              size="small"
              onChange={(event: any, newValue: ICountry | null) => {
                handleChangeCountry(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search..."
                  variant="outlined"
                />
              )}
            />
          )}
        </FormControl>
      </div>
      <div>
        <FormControl
          sx={{ width: "100%" }}
          size="small"
        >
          <Typography variant="caption">{t("language")}</Typography>
          {languages.length == 0 && <LoadingSkeleton />}
          {languages.length > 0 && (
            <Autocomplete
              value={languageValue}
              defaultValue={languageValue}
              options={languages}
              getOptionLabel={(options: ILanguage) => options.name}
              popupIcon={
                <i>
                  <ReactSVG src={ArrowDownIcon} />
                </i>
              }
              size="small"
              onChange={(event: any, newValue: ILanguage | null) => {
                handleChangeLanguage(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search..."
                  variant="outlined"
                />
              )}
            />
          )}
        </FormControl>
      </div>
      <div className="dialog__actions">
        <Button
          sx={{ backgroundColor: countryValue == null || languageValue == null ? "gray !important" : "" }}
          disabled={countryValue == null || languageValue == null}
          className="dialog-confirm-button"
          variant="contained"
          onClick={saveFormData}
        >
          {t("confirm")}
        </Button>
      </div>
    </>
  );
};

export default Content;
