import { Button, useTheme } from "@mui/material";
import { useStoreActions, useStoreState } from "../../../../hooks";
import DesignPreviewDialog, { designPreviewDialogRef } from "../../DesignPreview/DesignPreviewDialog";
import { ReactComponent as PreviewLiveIcon } from "../../Icons/preview-live.svg";
import "./preview-live.css";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../../../App";
import FitText from "../../../../components/FitText/FitText";

interface IPreviewLiveButton {
  productDesignId: string;
  isHorizontal?: boolean;
}

const PreviewLiveButton: React.FC<IPreviewLiveButton> = (props) => {
  const { t } = useTranslation();
  const ftsTheme = useTheme() as FTSTheme;

  const { setProductDesignPreview } = useStoreActions((actions) => actions.designs);
  const { productDesigns, fetchingCover } = useStoreState((state) => state.designs.data);

  const handleOpenPreview = () => {
    const productDesign = productDesigns.find((x) => x.id == props.productDesignId)!;
    setProductDesignPreview(productDesign);
    designPreviewDialogRef.current?.open();
  };

  return (
    <>
      <div className={`preview-live-content ${props.isHorizontal ? "" : "vertical"}`}>
        <Button
          sx={{
            color: fetchingCover ? "rgba(0, 0, 0, 0.26) !important" : ftsTheme.icon.color,
            border: `1px solid ${ftsTheme.icon.color}`,
            maxWidth: "200px",
          }}
          endIcon={
            <PreviewLiveIcon
              fill={ftsTheme.icon.color}
              style={{ marginRight: "0 !important" }}
            />
          }
          onClick={handleOpenPreview}
        >
          <FitText>{t("preview_live.button.preview_live")}</FitText>
        </Button>
      </div>
      <DesignPreviewDialog
        ref={designPreviewDialogRef}
        productDesignId={props.productDesignId}
      />
    </>
  );
};

export default PreviewLiveButton;
