import jwt_decode from "jwt-decode";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStoreActions } from "../hooks";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { IUser } from "../interfaces/IUser";
import { RegisterUserRequest } from "../interfaces/Register/Request/RegisterUserRequest";
import SSOService from "../services/SSOService";
import UserService from "../services/UserService";
import { useTranslation } from "react-i18next";

export const tokenExpired = () => {
  const access_token = localStorage.getItem("[FTS_Customisation_Center][access_token]") as string;
  if (!access_token) return true;

  const access_token_decoded = jwt_decode(access_token) as any;

  if (access_token_decoded.exp * 1000 < new Date().getTime()) {
    return true;
  } else {
    return false;
  }
};

export const tokenExpiring = () => {
  const access_token = localStorage.getItem("[FTS_Customisation_Center][access_token]") as string;
  if (access_token) {
    const access_token_decoded = jwt_decode(access_token) as any;

    // console.log('diff', (access_token_decoded.exp * 1000 - new Date(2022, 10, 4, 12, 26, 0).getTime()) / 1000 / 60)
    // if ((access_token_decoded.exp * 1000 - new Date(2022, 10, 4, 13, 1, 0).getTime()) / 1000 / 60 < 5) {
    if ((access_token_decoded.exp * 1000 - new Date().getTime()) / 1000 / 60 < 10) {
      return true;
    }
  }

  return false;
};

export const useAuth = () => {
  const [, SetCountryPopupOpened] = useLocalStorage("@FTS:CountryDialog", false);
  const { thunkOpenSnackBar } = useStoreActions((actions) => actions.app);
  const { setIsGuest, setCurrentUser } = useStoreActions((actions) => actions.auth);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const removeAllData = () => {
    localStorage.removeItem("[FTS_Customisation_Center][access_token]");
    localStorage.removeItem("[FTS_Customisation_Center][refresh_token]");
    localStorage.removeItem("[FTS_Customisation_Center][user_data]");
    SetCountryPopupOpened(false);
  };

  const setAccessToken = (accessToken: string) => {
    localStorage.setItem("[FTS_Customisation_Center][access_token]", accessToken);
  };

  const setRefreshToken = (refreshToken: string) => {
    localStorage.setItem("[FTS_Customisation_Center][refresh_token]", refreshToken);
  };

  const setUserData = (userData: any) => {
    localStorage.setItem("[FTS_Customisation_Center][user_data]", JSON.stringify(userData));
  };

  const authenticate = async (code: string) => {
    try {
      var tokenResult = await SSOService.getAccessToken(code);
      setAccessToken(tokenResult.data.access_token);
      setRefreshToken(tokenResult.data.refresh_token);

      var userDataResult = await SSOService.getUserData();
      const userData = userDataResult.data;
      setUserData(userData);

      const payload: RegisterUserRequest = {
        name: userData.realname,
        email: userData.email,
        userName: userData.username,
      };

      const userResponse = await UserService.registerUser(payload);

      const userDto = userResponse.data;

      setCurrentUser({
        email: userDto.email,
        name: userDto.name,
        countryId: userDto.countryId,
        languageId: userDto.languageId,
        isElBridgeAvailable: userDto.isElBridgeAvailable,
        termsAndConditionsAccepted: userDto.termsAndConditionsAccepted,
      });

      setIsGuest(false);
      navigate("/dashboard");
    } catch (error) {
      thunkOpenSnackBar({
        message: t("auth.message.validateuser.error"),
        severity: "error",
      });
    }
  };

  let userDataStorage = localStorage.getItem("[FTS_Customisation_Center][user_data]");
  let userData = userDataStorage ? (JSON.parse(userDataStorage) as IUser) : null;

  const [isTokenExpired] = useState(tokenExpired());
  const [isAuthenticated] = useState(!tokenExpired());
  const [user] = useState(userData);

  return {
    isAuthenticated,
    isTokenExpired,
    user,
    actions: {
      authenticate,
      setAccessToken,
      setRefreshToken,
      setUserData,
      removeAllData,
    },
  } as const;
};
