import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { EDesignStatus, EDesignStatusDescription } from "../../../../enum/EDesignStatus";
import { IProductDesignSummaryDto } from "../../../../interfaces/DTO/IProductDesignSummaryDto";
import "../DesignSummary.css";
import SetQuantityAndPrice from "./DesignSummaryTable-SetQuantityAndPrice";
import { useTranslation } from "react-i18next";
import { SetStateAction, useEffect, useRef, useState } from "react";
import Actions from "./DesignSummaryActions";
import { useNavigate } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../../../hooks";
import DesignPreviewDialog, { designPreviewDialogRef } from "../../DesignPreview/DesignPreviewDialog";
import DesignPreviewThumb from "../../DesignPreview/DesignPreviewThumb";
import { IProductDesignResponse } from "../../../../interfaces/Project/Response/ProductDesignResponse";
import { FTSTheme } from "../../../../App";
import { ReactSVG } from "react-svg";
import Badge from "@mui/material/Badge";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import EditSvgIcon from "../../Icons/EditIcon.svg";
import { IFoundFavoriteDesigns } from "../../../../interfaces/IFoundFavoriteDesigns";

interface DesignSummaryTableProps {
  setCoverCost: React.Dispatch<SetStateAction<number>>;
  productDesignSummary: IProductDesignSummaryDto[];
}

const DesignSummaryTable: React.FC<DesignSummaryTableProps> = (props) => {
  const { t } = useTranslation();
  const navigate: any = useNavigate();
  const ftsTheme = useTheme() as FTSTheme;
  const theme = useTheme();
  const { productDesigns } = useStoreState((state) => state.designs.data);
  const { currentProjectId } = useStoreState((state) => state.designs.configuration);

  const {
    thunkCheckFavoriteDesigns,
    setUserDesignDataFromProductDesignResponse,
    setProductDesignPreview,
    thunkGetProductDesignDataByProjectId,
    setUserDesignData,
  } = useStoreActions((actions) => actions.designs);

  const [checkedFavoriteDesigns, setCheckedFavoriteDesigns] = useState<IFoundFavoriteDesigns[]>([]);

  const checkInitialRun = useRef(false);

  useEffect(() => {
    if (checkInitialRun.current) {
      return;
    }
    checkInitialRun.current = true;

    const checkInitial = async () => {
      try {
        const response = await thunkCheckFavoriteDesigns();
        setCheckedFavoriteDesigns(response);
      } catch (error) {
        console.error("Error during thunk execution:", error);
      }
    };

    checkInitial();
  }, [checkedFavoriteDesigns]);

  useEffect(() => {
    thunkGetProductDesignDataByProjectId(currentProjectId!);
  }, []);

  const getBadgeContent = (productDesign) => {
    const markAsFavoriteDesign = checkedFavoriteDesigns.some((favoriteDesign) => {
      if (favoriteDesign?.productDesignId === productDesign.id) {
        return favoriteDesign.favoriteId !== null;
      }
      return false;
    });

    return markAsFavoriteDesign ? (
      <FavoriteIcon
        color="primary"
        className="designSummary_favoriteDesignIcon"
      />
    ) : (
      <FavoriteBorderIcon
        color="primary"
        className="designSummary_favoriteDesignIcon"
      />
    );
  };

  const onDraft = (productDesignId: string) => {
    const productDesign = productDesigns.find((x) => x.id == productDesignId)!;

    setUserDesignDataFromProductDesignResponse(productDesign);

    navigate(productDesign.design === null ? "/start-configurator" : productDesign.pathname, {
      state: { selectedCoverNumber: 0, isDesignSelected: true },
    });
  };

  const handleOpenDetails = (productDesign: IProductDesignResponse, numberOfCovers: number) => {
    productDesign.numberOfCovers = numberOfCovers;

    setUserDesignDataFromProductDesignResponse(productDesign!);
    setProductDesignPreview(productDesign);

    designPreviewDialogRef.current?.open();
  };

  const handleCloseComposition = () => {
    setUserDesignData(null);
  };

  return (
    <>
      <TableContainer
        sx={{
          "& .MuiTable-root": {
            border: "1px solid #DADADA",
            borderRadius: 4,
          },
        }}
      >
        <Table className={`designSummary__table`}>
          <TableHead className={`designSummaryTable__${ftsTheme.name}_table_head`}>
            <TableRow>
              <TableCell className="designSummaryTable_cell">{t("DesignSummary_HeaderPreview")}</TableCell>
              <TableCell className="designSummaryTable_cell">{t("DesignSummary_HeaderDetails")}</TableCell>
              <TableCell className="designSummaryTable_cell">{t("DesignSummary_SetQuantity")}</TableCell>
              {/* <TableCell className="designSummaryTable_cell" width={"100px"}>{t("DesignSummary_Price")}</TableCell> */}
              <TableCell className="designSummaryTable_cell">{t("DesignSummary_Status")}</TableCell>
              <TableCell className="designSummaryTable_cell">{t("table.head_cell.label_action")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.productDesignSummary.map((productDesign) => (
              <TableRow
                key={productDesign.id}
                className="designSummaryTable_row"
              >
                <TableCell className="designSummaryTable_cell">
                  <DesignPreviewThumb
                    productDesignId={productDesign.id}
                    productDesign={productDesigns.find((pd) => pd.id == productDesign.id)!}
                  />
                  <Badge
                    className="designSummary_favoriteDesignBadge"
                    badgeContent={getBadgeContent(productDesign)}
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  />
                </TableCell>
                <TableCell className="designSummaryTable_cell">
                  <Grid
                    container
                    className="designSummaryTable_cell_container"
                    rowSpacing={1}
                  >
                    <Grid
                      container
                      item
                      className="designSummaryTable_cell_cover"
                    >
                      {t("DesignSummary.room")}:<label className="detail">{productDesign.room}</label>
                    </Grid>

                    <Grid
                      container
                      item
                      className="designSummaryTable_cell_cover"
                    >
                      {t("DesignSummary.location")}:<label className="detail">{productDesign.location}</label>
                    </Grid>
                    <Grid
                      container
                      item
                      className="designSummaryTable_cell_cover"
                    >
                      {productDesign.numberOfCovers === 1
                        ? t("design_summary_table.cover")
                        : t("design_summary_table.cover_set")}
                    </Grid>
                    <Grid
                      container
                      item
                      className="designSummaryTable_cell_designrange"
                    >
                      {t("DesignSummary_DesignRange")} <label className="detail">{productDesign.designRange}</label>
                    </Grid>
                    <Grid
                      container
                      item
                      className="designSummaryTable_cell_numbercovers"
                    >
                      {t("DesignSummary_NumberOfCovers")}{" "}
                      <label className="detail">{productDesign.numberOfCovers}</label>
                    </Grid>
                    <Grid
                      container
                      item
                    >
                      <Button
                        onClick={() =>
                          handleOpenDetails(
                            productDesigns.find((pd) => pd.id == productDesign.id)!,
                            productDesign.numberOfCovers
                          )
                        }
                        variant="text"
                        className="designSummaryTable_cell_seedetails"
                      >
                        {t("DesignSummary_SeeDetails")}
                      </Button>
                    </Grid>
                  </Grid>
                </TableCell>
                <SetQuantityAndPrice
                  id={productDesign.id}
                  price={productDesign.price}
                  quantity={productDesign.quantity}
                  numberOfCovers={productDesign.numberOfCovers}
                  designStatus={productDesign.designStatus}
                  productDesign={productDesigns.find((pd) => pd.id == productDesign.id)!}
                  setCoverCost={props.setCoverCost}
                />
                <TableCell
                  className={`designSummaryTable_cell${
                    productDesign.designStatus == EDesignStatus.Draft ? "draft" : ""
                  }`}
                >
                  {(productDesign.designStatus == EDesignStatus.Draft && (
                    <Button
                      onClick={() => onDraft(productDesign.id)}
                      sx={{ textTransform: "capitalize", fontSize: "16px", fontWeight: 400, lineHeight: "20px" }}
                      startIcon={
                        <ReactSVG
                          src={EditSvgIcon}
                          beforeInjection={(svg) => {
                            svg.setAttribute("style", `width: 16px; height: 16px; fill: ${theme.palette.primary.main}`);
                          }}
                        />
                      }
                    >
                      {t(`${EDesignStatusDescription.get(productDesign.designStatus)}`)}
                    </Button>
                  )) || (
                    <Typography
                      sx={{
                        color: "#000000",
                        textTransform: "capitalize",
                        fontSize: "16px",
                        fontWeight: 400,
                        lineHeight: "20px",
                      }}
                    >
                      {t(`${EDesignStatusDescription.get(productDesign.designStatus)}`)}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <Actions
                    productDesignId={productDesign.id}
                    designStatus={productDesign.designStatus}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <DesignPreviewDialog
        handleCloseComposition={handleCloseComposition}
        displayPreviewTabs
        ref={designPreviewDialogRef}
        checkedFavoriteDesigns={checkedFavoriteDesigns}
        setCheckedFavoriteDesigns={setCheckedFavoriteDesigns}
      />
    </>
  );
};

export default DesignSummaryTable;
