import { AlertColor } from "@mui/material/Alert";
import { Action, action, thunk, Thunk } from "easy-peasy";
import { IAppConfirmButtonInputProps, IAppConfirmInputProps } from "../components/Confirm/Confirm";
import { ISnackBarComponentProps } from "../components/SnackBar/SnackBar";

export interface IAppModel {
  isGrid: boolean;
  isExportingXLSX: boolean;
  isExportingPDF: boolean;
  openCountryDialog: boolean;
  openIconDialog: boolean;
  showSnackBar: boolean;
  snackBarSeverity: AlertColor;
  snackBarMessage: string;
  showConfirm: boolean;
  highlightCancel: boolean;
  confirmTitle: string;
  confirmSubtitle: string;
  confirmMessage: string;
  confirmOnConfirm: IAppConfirmButtonInputProps | null;
  confirmOnDismiss: IAppConfirmButtonInputProps | null;
  confirmUseLoading: boolean;
  openProductDesignDialog: boolean;
  enableDimensionComponent: boolean;
  setEnableDimensionComponent: Action<IAppModel, boolean>;
  setSnackBarSeverity: Action<IAppModel, AlertColor>;
  setIsGrid: Action<IAppModel, boolean>;
  setIsExportingXLSX: Action<IAppModel, boolean>;
  setIsExportingPDF: Action<IAppModel, boolean>;
  setShowSnackBar: Action<IAppModel, boolean>;
  setShowConfirm: Action<IAppModel, boolean>;
  setHighlightCancel: Action<IAppModel, boolean>;
  setConfirmTitle: Action<IAppModel, string>;
  setConfirmSubtitle: Action<IAppModel, string>;
  setConfirmMessage: Action<IAppModel, string>;
  setConfirmOnConfirm: Action<IAppModel, IAppConfirmButtonInputProps | null>;
  setConfirmOnDismiss: Action<IAppModel, IAppConfirmButtonInputProps | null>;
  setConfirmUseLoading: Action<IAppModel, boolean>;
  setOpenCountryDialog: Action<IAppModel, boolean>;
  setOpenIconDialog: Action<IAppModel, boolean>;
  setOpenProductDesignDialog: Action<IAppModel, boolean>;
  setSnackBarMessage: Action<IAppModel, string>;
  thunkOpenSnackBar: Thunk<IAppModel, ISnackBarComponentProps>;
  thunkCloseSnackBar: Thunk<IAppModel>;
  thunkOpenConfirm: Thunk<IAppModel, IAppConfirmInputProps>;
  thunkCloseConfirm: Thunk<IAppModel>;
}

const app: IAppModel = {
  isGrid: false,
  isExportingXLSX: false,
  isExportingPDF: false,
  openCountryDialog: false,
  openIconDialog: false,
  openProductDesignDialog: false,
  snackBarSeverity: "success",
  setSnackBarSeverity: action((state, payload) => {
    state.snackBarSeverity = payload;
  }),
  showSnackBar: false,
  snackBarMessage: "",
  showConfirm: false,
  highlightCancel: false,
  confirmTitle: "",
  confirmSubtitle: "",
  confirmMessage: "",
  confirmOnConfirm: null,
  confirmOnDismiss: null,
  confirmUseLoading: false,
  enableDimensionComponent: false,
  setEnableDimensionComponent: action((state, payload) => {
    state.enableDimensionComponent = payload;
  }),
  setSnackBarMessage: action((state, payload) => {
    state.snackBarMessage = payload;
  }),
  setShowConfirm: action((state, payload) => {
    state.showConfirm = payload;
  }),
  setHighlightCancel: action((state, payload) => {
    state.highlightCancel = payload;
  }),
  setConfirmTitle: action((state, payload) => {
    state.confirmTitle = payload;
  }),
  setConfirmSubtitle: action((state, payload) => {
    state.confirmSubtitle = payload;
  }),
  setConfirmMessage: action((state, payload) => {
    state.confirmMessage = payload;
  }),
  setConfirmOnConfirm: action((state, payload) => {
    state.confirmOnConfirm = payload;
  }),
  setConfirmOnDismiss: action((state, payload) => {
    state.confirmOnDismiss = payload;
  }),
  setConfirmUseLoading: action((state, payload) => {
    state.confirmUseLoading = payload;
  }),
  setIsGrid: action((state, payload) => {
    state.isGrid = payload;
  }),
  setIsExportingXLSX: action((state, payload) => {
    state.isExportingXLSX = payload;
  }),
  setIsExportingPDF: action((state, payload) => {
    state.isExportingPDF = payload;
  }),
  setOpenCountryDialog: action((state, payload) => {
    state.openCountryDialog = payload;
  }),
  setOpenIconDialog: action((state, payload) => {
    state.openIconDialog = payload;
  }),
  setOpenProductDesignDialog: action((state, payload) => {
    state.openProductDesignDialog = payload;
  }),
  setShowSnackBar: action((state, payload) => {
    state.showSnackBar = payload;
  }),
  thunkOpenSnackBar: thunk((actions, payload) => {
    actions.setSnackBarMessage(payload.message);
    actions.setSnackBarSeverity(payload.severity);
    actions.setShowSnackBar(true);
  }),
  thunkCloseSnackBar: thunk((actions) => {
    actions.setSnackBarMessage("");
    actions.setSnackBarSeverity("info");
    actions.setShowSnackBar(false);
  }),
  thunkOpenConfirm: thunk((actions, payload) => {
    actions.setShowConfirm(true);
    actions.setConfirmTitle(payload.title);
    actions.setConfirmSubtitle(payload.subtitle ?? ``);
    actions.setConfirmMessage(payload.message);
    actions.setConfirmOnConfirm(payload.onConfirm ?? null);
    actions.setConfirmOnDismiss(payload.onDismiss ?? null);
    actions.setConfirmUseLoading(payload.useLoading ?? false);
    actions.setHighlightCancel(payload.highlightCancel ?? false);
  }),
  thunkCloseConfirm: thunk((actions) => {
    actions.setShowConfirm(false);
    actions.setConfirmTitle("");
    actions.setConfirmSubtitle("");
    actions.setConfirmMessage("");
    actions.setConfirmOnConfirm(null);
    actions.setConfirmOnConfirm(null);
    actions.setConfirmUseLoading(false);
  }),
};

export default app;
