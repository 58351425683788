import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box, Grid, Modal, Typography, Button, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { useStoreActions, useStoreState } from "../../../hooks";
import { IInsertDetail, IInsertDetailItem } from "../../../interfaces/IInsertDetail";
import { IUserDesignData } from "../../../interfaces/IUserDesignData";
import { Status } from "../../../models/status";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface CloneDialogProps {
  insertDetails: IInsertDetail | null;
  setOpenCloneDialog: Dispatch<SetStateAction<boolean>>;
  setCloseCloneDialog: Dispatch<SetStateAction<boolean>>;
  setIconDialogOpen: Dispatch<SetStateAction<boolean>>;
}

type InsertConfigurationState = {
  selectedCoverNumber?: number;
  isDesignSelected?: boolean;
};

const CloneDialog: React.FC<CloneDialogProps> = (props) => {
  const location = useLocation();
  const { t } = useTranslation();

  const state = location.state as InsertConfigurationState | null;

  const [selectedOption, setSelectedOption] = React.useState<string>("");
  const { userDesignData } = useStoreState((state) => state.designs.configuration);
  const [insertDetailsList, setInsertDetailsList] = React.useState<IInsertDetail[]>([]);
  const [selectedCoverNumber, setSelectedCoverNumber] = useState(state?.selectedCoverNumber ?? 0);
  const { thunkOpenConfirm, thunkCloseConfirm } = useStoreActions((actions) => actions.app);

  const { thunkCloneCover } = useStoreActions((actions) => actions.designs);
  const { thunkOpenSnackBar } = useStoreActions((actions) => actions.app);

  const { frameFolds } = useStoreState((state) => state.designs.data);
  useEffect(() => {
    if (userDesignData && userDesignData.userCustomDesign) {
      const filteredList = userDesignData.userCustomDesign.insertDetails.filter(
        (detail) => detail.id !== props.insertDetails?.id
      );
      setInsertDetailsList(filteredList);
    }
  }, [props.insertDetails, userDesignData]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value);
  };
  const handleCloneCover = async () => {
    let selectedDetail = userDesignData?.userCustomDesign?.insertDetails.find((detail) => detail.id === selectedOption);
    const hasItems = props.insertDetails!.items?.length > 0;
    const newNumberOfSwitches: number = userDesignData?.numberOfSwitches!;
    const newFrameFold = frameFolds.find((ff) => ff.frameCode == newNumberOfSwitches.toString()!);
    const newInsertDetails = JSON.parse(
      JSON.stringify(userDesignData?.userCustomDesign.insertDetails)
    ) as IInsertDetail[];
    if (hasItems) {
      props.insertDetails!.items = [];
    }
    const items = selectedDetail!.items.map((item) => {
      delete item.id;
      delete item.insertDetailId;
      return item;
    });

    const itemsCloned = JSON.parse(JSON.stringify(items)) as IInsertDetailItem[];
    const insertDetailToUpdate = newInsertDetails.findIndex((detail) => detail.id === props.insertDetails?.id);

    const updatedDetail: IInsertDetail = {
      ...newInsertDetails[insertDetailToUpdate],
      isFlipped: selectedDetail?.isFlipped,
      insertType: selectedDetail?.insertType,
      insertColor: selectedDetail?.insertColor,
      rotate: selectedDetail?.rotate,
      hasGridLine: selectedDetail?.hasGridLine,
      items: itemsCloned,
    };
    newInsertDetails[insertDetailToUpdate] = updatedDetail;
    const newValues: IUserDesignData = {
      ...userDesignData!,
      ...{
        userCustomDesign: {
          ...userDesignData?.userCustomDesign!,
          ...{
            frameFold: newFrameFold,
            insertDetails: newInsertDetails!,
          },
        },
      },
    };
    if (hasItems) {
      let confirmAction = false;
      thunkOpenConfirm({
        title: t("InsertConfiguration_CloneDialog_Erase_Title"),
        message: t("InsertConfiguration_CloneDialog_Erase_Content"),
        onDismiss: {
          text: t("InsertConfiguration_CloneDialog_Erase_Cancel_Button"),
        },
        onConfirm: {
          text: t("InsertConfiguration_CloneDialog_Erase_Confirmation_Button"),
          onClick: () => handleUpdateCover(newValues),
        },
        useLoading: true,
      });

      if (!confirmAction) {
        return;
      }
    } else {
      handleUpdateCover(newValues);
    }
  };
  const handleUpdateCover = async (userDesignData: IUserDesignData) => {
    var response = await thunkCloneCover(userDesignData);
    if (response.status == Status.COMPLETED) {
      setSelectedCoverNumber(userDesignData.numberOfSwitches!)!;
    }

    if (response.status == Status.FAILED) {
      thunkOpenSnackBar({
        message: response.message,
        severity: "error",
      });
    }
    handleClose();
    await thunkCloseConfirm();
    props.setIconDialogOpen(false);
  };

  const handleClose = () => {
    props.setOpenCloneDialog(false);
    props.setCloseCloneDialog(true);
  };

  return (
    <Modal
      open={true} // Set this to true to show the modal
      onClose={() => {
        handleClose();
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ bgcolor: "white", p: 2, width: 400 }}>
        <Typography
          variant="h4"
          sx={{ fontSize: "16px", fontWeight: 700, lineHeight: "20px" }}
        >
          {t("InsertConfiguration_CloneDialog_title")}
        </Typography>
        <Select
          value={selectedOption}
          onChange={handleChange}
          fullWidth
          displayEmpty
          inputProps={{ "aria-label": "Select option" }}
          sx={{ mt: 2 }}
        >
          <MenuItem
            value=""
            disabled
          >
            {t("InsertConfiguration_CloneDialog_placeholder")}
          </MenuItem>
          {insertDetailsList.map((detail, index) => (
            <MenuItem
              key={detail.id}
              value={detail.id}
            >
              {t("InsertConfiguration_CloneDialog_SelectText") + " " + detail.order}
            </MenuItem>
          ))}
        </Select>
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          sx={{ mt: 2 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                handleClose();
              }}
            >
              {t("InsertConfiguration_CloneDialog_Cancel_Button")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                handleCloneCover();
              }}
            >
              {t("InsertConfiguration_CloneDialog_Confirmation_Button")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default CloneDialog;
