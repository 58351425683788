import { Link, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FTSTheme } from "../../App";

interface INavbarLogoProps {
  link: string;
}

export function NavbarLogo({ link }: INavbarLogoProps) {
  const theme = useTheme() as FTSTheme;
  const navigate = useNavigate();

  return (
    <>
      <div className="navbar__logo">
        <Link
          onClick={() => navigate(link)}
          sx={{ cursor: "pointer", textDecoration: "none" }}
        >
          {theme.name == "bje" && (
            <img
              src="/logo.svg"
              alt="logo"
              className="bje"
            />
          )}
          {theme.name == "abb" && (
            <img
              src="/abb-logo.svg"
              alt="logo"
              className="abb"
            />
          )}
        </Link>
      </div>
    </>
  );
}
