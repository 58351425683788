import { Typography, TypographyProps, TypographyTypeMap } from "@mui/material";
import { ReactNode } from "react";

interface IShowMoreProps extends TypographyProps {
  textLength?: number;
  noFitText?: boolean;
}

export const FitText: React.FC<IShowMoreProps> = (props) => {
  let textOverflowProps: any = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // width: '100%',
  };
  let textToWrite = props.children?.toString();
  if (props.textLength && textToWrite) {
    if (textToWrite.length > props.textLength) {
      textToWrite = textToWrite.slice(0, props.textLength) + "...";
      textOverflowProps = null;
    }
  }

  return (
    <>
      {(props.noFitText == true && props.children) || (
        <Typography
          title={props.children?.toString()}
          style={{ ...textOverflowProps }}
          {...props}
        >
          {textToWrite}
        </Typography>
      )}
    </>
  );
};

export default FitText;
