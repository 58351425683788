import { Skeleton } from "@mui/material";

const LoadingSkeleton: React.FC = () => {
  return (
    <div>
      <Skeleton
        variant="rectangular"
        width={340}
        height={40}
      />
    </div>
  );
};

export default LoadingSkeleton;
