import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { Grid, Typography } from "@mui/material";
import InfoIcon from "../../Icons/Info2.svg";
import { useStoreState } from "../../../../hooks";
import FindDistributorDialog, { findDistributorDialogRef } from "../FindDistributor/FindDistributorDialog";
import { EDesignStatus } from "../../../../enum/EDesignStatus";
import { ReactSVG } from "react-svg";
import FindDistributorButton from "../FindDistributorButton";
import { useTheme } from "@emotion/react";
import { FTSTheme } from "../../../../App";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "../../Icons/download.svg";
import { downloadProjectDialogRef } from "../Download/DownloadProjectDialog";

interface IOrderSummaryProps {
  refreshProductDesign: () => Promise<void>;
}

const WithElBridge: React.FC<IOrderSummaryProps> = ({ refreshProductDesign }) => {
  const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState(true);
  const { productDesignSummary, fetchingData, selectedProjectsWithProductsIds } = useStoreState(
    (state) => state.designs.data
  );
  const { t } = useTranslation();
  const ftsTheme = useTheme() as FTSTheme;

  useEffect(() => {
    setIsDownloadButtonDisabled(isEmpty(selectedProjectsWithProductsIds));
  }, [selectedProjectsWithProductsIds]);

  const handleOpenFindDistributor = async () => {
    await refreshProductDesign();
    findDistributorDialogRef.current?.open();
  };

  return (
    <>
      <div className="projectSummary__mainSummary">
        <div className="projectSummary__mainSummaryTitle">
          <Typography
            variant="h4"
            fontSize="18px"
            fontWeight={700}
          >
            {t("DesignSummary_OrderSummary")}
          </Typography>
        </div>

        <Grid
          container
          md={12}
          mt={1}
        >
          <Typography
            variant="h4"
            fontSize="14px"
            fontWeight={400}
          >
            {t("ProjectSummary_MainDescription")}
          </Typography>
        </Grid>

        <Grid
          container
          md={12}
        >
          <div className={`${ftsTheme.name}_projectSummary__container_info`}>
            <div>
              <ReactSVG
                src={InfoIcon}
                beforeInjection={(svg) => {
                  svg.setAttribute(
                    "style",
                    `width: 16px; height: 16px; fill: ${ftsTheme.icon.color}; padding: 0px; margin-top:5px`
                  );
                }}
              />
            </div>
            <div className="projectSummary__info">
              <Typography
                variant="body1"
                fontSize="14px"
                fontWeight={400}
              >
                <Trans
                  i18nKey="DesignSummary_Info"
                  components={{ bold: <strong /> }}
                />
              </Typography>
            </div>
          </div>
        </Grid>

        <div className="projectSummary__mainSummaryDivider"></div>

        <div className={`projectSummary__mainSummaryButtons`}>
          <FindDistributorButton
            loading={fetchingData}
            disabled={
              productDesignSummary.map((x) => x.designStatus).includes(EDesignStatus.Draft) ||
              productDesignSummary.length === 0
            }
            handleOpenFindDistributor={handleOpenFindDistributor}
          />
        </div>

        <div className={`projectSummary__mainSummaryButtons`}>
          <LoadingButton
            onClick={() => downloadProjectDialogRef.current?.open()}
            variant="outlined"
            disabled={isDownloadButtonDisabled}
            endIcon={
              <ReactSVG
                src={DownloadIcon}
                beforeInjection={(svg) => {
                  svg.setAttribute("style", `width: 16px; height: 16px; color: ${ftsTheme.icon.color}; fill: white`);
                }}
              />
            }
          >
            <Typography>{t("DesignSummary_DownloadProject")}</Typography>
          </LoadingButton>
        </div>
      </div>

      {!fetchingData && (
        <FindDistributorDialog
          elBridge={productDesignSummary.flatMap((x) => x.elBridge)}
          ref={findDistributorDialogRef}
        />
      )}
    </>
  );
};

export default WithElBridge;
