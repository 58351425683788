import {
  BUTTON,
  FOUR_BUTTONS,
  ONE_BUTTON_ONE_ROCKER,
  ONE_SLIDER_ONE_BUTTON,
  ONE_SLIDER_ONE_ROCKER,
  ONE_SLIDER_TWO_BUTTONS,
  ROCKER,
  SLIDER,
  TWO_BUTTONS,
  TWO_BUTTONS_ONE_ROCKER,
  TWO_ROCKERS,
  TWO_SLIDERS,
} from "../constants/InsertType";

export enum EInsertType {
  Button = "Button",
  Slider = "Slider",
  Rocker = "Rocker",
  OneSliderOneRocker = "1 Slider | 1 Rocker",
  OneSliderOneButton = "1 Slider | 1 Button",
  TwoButtons = "2 Buttons",
  TwoRockers = "2 Rockers",
  TwoSliders = "2 Sliders",
  OneButtonOneRocker = "1 Button | 1 Rocker",
  OneSliderTwoButtons = "1 Slider | 2 Buttons",
  TwoButtonsOneRocker = "2 Buttons | 1 Rocker",
  FourButtons = "4 Buttons",
}

interface ITileProps {
  key: string;
  value: string;
}

export const OneTile: ITileProps[] = [
  { key: "insert_configuration.insert_type.button", value: EInsertType.Button.toString() },
  { key: "insert_configuration.insert_type.rocker", value: EInsertType.Rocker.toString() },
  { key: "insert_configuration.insert_type.slider", value: EInsertType.Slider.toString() },
];

export const TwoTiles: ITileProps[] = [
  { key: "insert_configuration.insert_type.two_sliders", value: EInsertType.TwoSliders.toString() },
  { key: "insert_configuration.insert_type.one_slider_one_rocker", value: EInsertType.OneSliderOneRocker.toString() },
  { key: "insert_configuration.insert_type.one_slider_one_button", value: EInsertType.OneSliderOneButton.toString() },
  { key: "insert_configuration.insert_type.two_buttons", value: EInsertType.TwoButtons.toString() },
  { key: "insert_configuration.insert_type.two_rockers", value: EInsertType.TwoRockers.toString() },
  { key: "insert_configuration.insert_type.one_button_one_rocker", value: EInsertType.OneButtonOneRocker.toString() },
];

export const ThreeTiles: ITileProps[] = [
  { key: "insert_configuration.insert_type.one_slider_two_buttons", value: EInsertType.OneSliderTwoButtons.toString() },
  { key: "insert_configuration.insert_type.two_buttons_one_rocker", value: EInsertType.TwoButtonsOneRocker.toString() },
];

export const FourTiles: ITileProps[] = [
  { key: "insert_configuration.insert_type.four_buttons", value: EInsertType.FourButtons.toString() },
];

export const ETilesByInsertType = new Map<string, number>([
  [BUTTON, 1],
  [SLIDER, 1],
  [ROCKER, 3],
  [ONE_SLIDER_TWO_BUTTONS, 3],
  [ONE_SLIDER_ONE_ROCKER, 4],
  [ONE_BUTTON_ONE_ROCKER, 4],
  [FOUR_BUTTONS, 4],
  [TWO_SLIDERS, 2],
  [ONE_SLIDER_ONE_BUTTON, 2],
  [TWO_BUTTONS, 2],
  [TWO_BUTTONS_ONE_ROCKER, 5],
  [TWO_ROCKERS, 6],
]);
