import axios, { AxiosResponse } from "axios";
import axiosInstance from "../config/axios";
import { IProductDesignSummaryDto } from "../interfaces/DTO/IProductDesignSummaryDto";
import { IProductDesignFilter } from "../interfaces/Filter/IProductDesignFilter";
import { IProductDesignCreateRequest } from "../interfaces/Project/Request/IProductDesignCreateRequest";
import { IProductDesignUpdateRequest } from "../interfaces/Project/Request/IProductDesignUpdateRequest";
import { IProductDesignResponse } from "../interfaces/Project/Response/ProductDesignResponse";
import { IProductDesignSummaryResponse } from "../interfaces/Project/Response/ProductDesignSummaryResponse";
import { ICoverImageDto } from "../interfaces/DTO/ICoverImageDto";
import { IAddNewProductDesignRequest } from "../interfaces/IAddNewProductDesignRequest";

const responseBody = (response: AxiosResponse) => response;

const requests = {
  get: (url: string, options?: {}) => axiosInstance.get(url, options).then(responseBody),
  post: (url: string, body: {}, options: {}) => axiosInstance.post(url, body, options).then(responseBody),
  put: (url: string, body: {}, options: {}) => axiosInstance.put(url, body, options).then(responseBody),
  delete: (url: string) => axiosInstance.delete(url).then(responseBody),
};

const ProductDesignService = {
  get: (filter: IProductDesignFilter) => {
    return axiosInstance.get<IProductDesignSummaryDto[]>("productdesign/summaries", {
      params: { ...filter },
    });
  },
  getProductDesignByProjectId: (projectId: string) => {
    return axiosInstance.get<IProductDesignResponse[]>(`productdesign/project/${projectId}`);
  },
  getSummarDetailsByProjectId: (projectId: string[]) => {
    const queryParams = projectId.map((id) => `projectId=${id}`).join("&");
    const url = `productdesign/project/summaryDetails?${queryParams}`;

    return axiosInstance.get<IProductDesignSummaryResponse[]>(url);
  },
  createProductDesign: (payload: IProductDesignCreateRequest): Promise<AxiosResponse<string | null>> => {
    return requests.post("productdesign", payload, {
      headers: {
        "Content-Type": "text/plain",
      },
    });
  },
  addNewProductDesign: (payload: IAddNewProductDesignRequest) => {
    return axiosInstance.post<IProductDesignResponse>("productdesign/add-new-product-design", payload);
  },
  updateProductDesign: (payload: IProductDesignUpdateRequest): Promise<AxiosResponse<IProductDesignResponse>> => {
    return requests.put("productdesign", payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  deleteProductDesign: (id: string): Promise<AxiosResponse> => requests.delete(`productdesign/${id}`),

  uploadCoverAzStorage: (covers: ICoverImageDto[]): Promise<AxiosResponse> => {
    return requests.post("productdesign/cover/add-covers-az-storage", covers, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  deleteCoverAzStorage: (coverDesignsId: string[]): Promise<AxiosResponse> => {
    return requests.post("productdesign/cover/delete-covers-az-storage", coverDesignsId, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};

export default ProductDesignService;
