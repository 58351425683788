import { Box, Button, ButtonProps, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../../App";
import { ABB, BJE } from "../../../constants/Theme";
import FitText from "../../../components/FitText/FitText";

interface IButtonProps extends ButtonProps {
  selectedCoverNumber: number;
  numberOfSwitches: number;

  handlePreviousCover: () => void;
  handleNextCover: () => void;
  handleGoToSummary: () => Promise<void>;
}

const AbbCoverControl: React.FC<IButtonProps> = ({
  selectedCoverNumber,
  numberOfSwitches,
  handlePreviousCover,
  handleNextCover,
  handleGoToSummary,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {selectedCoverNumber > 0 && (
          <Button
            variant="outlined"
            onClick={handlePreviousCover}
            className="btnPreviousCover"
            sx={{
              width: 404,
              height: 44,
            }}
            startIcon={
              <svg
                style={{ marginRight: "11px" }}
                width="14"
                height="11"
                viewBox="0 0 14 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.5 11L7 9.5L4 6.5L14 6.5L14 4.5L4 4.5L7 1.5L5.5 -7.43094e-07L4.80825e-07 5.5L5.5 11Z"
                  fill={theme.palette.primary.main}
                />
              </svg>
            }
          >
            <FitText>{t("insert_configuration.button.previous_cover")}</FitText>
          </Button>
        )}

        {selectedCoverNumber < numberOfSwitches! - 1 && (
          <Button
            sx={{ width: 404, height: 44, marginLeft: "16px" }}
            variant="contained"
            onClick={handleNextCover}
            className="btnNextCover"
            endIcon={
              <svg
                style={{ marginLeft: "11px" }}
                width="14"
                height="11"
                viewBox="0 0 14 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.5 0L7 1.5L10 4.5L0 4.5L0 6.5L10 6.5L7 9.5L8.5 11L14 5.5L8.5 0Z"
                  fill="white"
                />
              </svg>
            }
          >
            <FitText sx={{ fontSize: 18, fontWeight: 500 }}>{t("insert_configuration.button.next_cover")}</FitText>
          </Button>
        )}

        {selectedCoverNumber == numberOfSwitches! - 1 && (
          <Button
            variant="contained"
            onClick={handleGoToSummary}
            className="btnGoToSummary"
            sx={{
              width: 404,
              height: 44,
              marginLeft: "16px",
            }}
            endIcon={
              <svg
                style={{ marginLeft: "11px" }}
                width="14"
                height="11"
                viewBox="0 0 14 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.5 0L7 1.5L10 4.5L0 4.5L0 6.5L10 6.5L7 9.5L8.5 11L14 5.5L8.5 0Z"
                  fill="white"
                />
              </svg>
            }
          >
            <FitText fontWeight={700}>{t("insert_configuration.button.go_to_summary")}</FitText>
          </Button>
        )}
      </Box>
    </>
  );
};

const BjeCoverControl: React.FC<IButtonProps> = ({
  selectedCoverNumber,
  numberOfSwitches,
  handlePreviousCover,
  handleNextCover,
  handleGoToSummary,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {selectedCoverNumber > 0 && (
        <Button
          variant="outlined"
          onClick={handlePreviousCover}
          className="btnPreviousCover"
          sx={{
            width: 436,
            height: 56,
            whiteSpace: "normal",
            overflow: "hidden",
          }}
          startIcon={
            <svg
              style={{ marginRight: "11px" }}
              width="14"
              height="11"
              viewBox="0 0 14 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.5 11L7 9.5L4 6.5L14 6.5L14 4.5L4 4.5L7 1.5L5.5 -7.43094e-07L4.80825e-07 5.5L5.5 11Z"
                fill={theme.palette.primary.main}
              />
            </svg>
          }
        >
          <FitText sx={{ fontSize: 18, fontWeight: 700 }}>{t("insert_configuration.button.previous_cover")}</FitText>
        </Button>
      )}

      {selectedCoverNumber < numberOfSwitches! - 1 && (
        <Button
          variant="contained"
          onClick={handleNextCover}
          className="btnNextCover"
          sx={{
            width: 436,
            height: 56,
            marginLeft: "20px",
            whiteSpace: "normal",
            overflow: "hidden",
          }}
          endIcon={
            <svg
              style={{ marginLeft: "11px" }}
              width="14"
              height="11"
              viewBox="0 0 14 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 0L7 1.5L10 4.5L0 4.5L0 6.5L10 6.5L7 9.5L8.5 11L14 5.5L8.5 0Z"
                fill="white"
              />
            </svg>
          }
        >
          <FitText sx={{ fontSize: 18, fontWeight: 700 }}>{t("insert_configuration.button.next_cover")}</FitText>
        </Button>
      )}
      {selectedCoverNumber == numberOfSwitches! - 1 && (
        <Button
          variant="contained"
          onClick={handleGoToSummary}
          className="btnGoToSummary"
          sx={{
            width: 436,
            height: 56,
            marginLeft: "20px",
            whiteSpace: "normal",
            overflow: "hidden",
          }}
          endIcon={
            <svg
              style={{ marginLeft: "11px" }}
              width="14"
              height="11"
              viewBox="0 0 14 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 0L7 1.5L10 4.5L0 4.5L0 6.5L10 6.5L7 9.5L8.5 11L14 5.5L8.5 0Z"
                fill="white"
              />
            </svg>
          }
        >
          <FitText fontWeight={700}>{t("insert_configuration.button.go_to_summary")}</FitText>
        </Button>
      )}
    </Box>
  );
};

const CoverControlButton: React.FC<IButtonProps> = ({
  selectedCoverNumber,
  numberOfSwitches,
  handlePreviousCover,
  handleNextCover,
  handleGoToSummary,
}) => {
  const theme = useTheme() as FTSTheme;

  return (
    <>
      {(theme.name == ABB && (
        <AbbCoverControl
          selectedCoverNumber={selectedCoverNumber}
          numberOfSwitches={numberOfSwitches}
          handlePreviousCover={handlePreviousCover}
          handleNextCover={handleNextCover}
          handleGoToSummary={handleGoToSummary}
        />
      )) ||
        (theme.name == BJE && (
          <BjeCoverControl
            selectedCoverNumber={selectedCoverNumber}
            numberOfSwitches={numberOfSwitches}
            handlePreviousCover={handlePreviousCover}
            handleNextCover={handleNextCover}
            handleGoToSummary={handleGoToSummary}
          />
        ))}
    </>
  );
};

export default CoverControlButton;
