import { useTranslation } from "react-i18next";
import Demo from "../../../components/Demo/Demo";
import RecentProjects from "../RecentProjects/RecentProjects";
import RecentFavoriteDesigns from "../RecentFavoriteDesigns/RecentFavoriteDesigns";
import { useStoreRehydrated } from "easy-peasy";

const DashboardContent: React.FC = () => {
  const { t } = useTranslation();
  const isRehydrated = useStoreRehydrated();

  return (
    <>
      {!isRehydrated ? (
        <div>{t("dashboard.div_loading")}</div>
      ) : (
        <>
          <div className="demoContainer">
            <Demo />
            <RecentProjects />
          </div>
          <div className="favoriteContainer">
            <RecentFavoriteDesigns />
          </div>
        </>
      )}
    </>
  );
};

export default DashboardContent;
