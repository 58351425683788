import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ExternalLayout from "../../../components/Layout/ExternalLayout";
import Spinner from "../../../components/Spinner/Spinner";
import { useStoreActions, useStoreState } from "../../../hooks";
import { IFrameColor } from "../../../interfaces/IFrameColor";
import { IInsertDetail } from "../../../interfaces/IInsertDetail";
import Cover from "../Components/Cover/Cover";
import Frame from "../Components/Frame/Frame";
import { ReactComponent as RectangleHorizontal } from "../Icons/horizontal_icon.svg";
import { ReactComponent as QuestionMark2 } from "../Icons/Info.svg";
import { ReactComponent as InfoSign } from "../Icons/Info2.svg";
import { ReactComponent as RectangleVertical } from "../Icons/vertical_icon.svg";
import DesignRangeConfirmationDialog, { designRangeConfirmationDialogRef } from "./DesignRangeConfirmationDialog";
import "./FrameConfiguration.css";
import { useTranslation } from "react-i18next";
import { Typography, useTheme } from "@mui/material";
import { IProjectSummaryDto } from "../../../interfaces/DTO/IProjectSummaryDto";
import NextButton from "./NextButton";
import { FTSTheme } from "../../../App";
import HelpCustomizationButton from "./HelpCustomizationButton";
import HowManySwitchesCounter from "./HowManySwitches";
import FrameColorContainer from "./FrameColorContainer";
import useAppTitle from "../../../hooks/useAppTitle";
import EditDialog, { EditButton, editDialogRef } from "../DesignSummary/Edit/EditDialog";
import { MAX_NUMBER_OF_COVERS, NUMBER_OF_SINGLE_COVER } from "../../../constants/NumberValues";

type FrameConfigurationState = {
  isDesignSelected?: boolean;
};

const FrameConfiguration: FC<{}> = () => {
  const theme = useTheme();
  const ftsTheme = useTheme() as FTSTheme;

  const { frameFolds, insertTypes, projectsSummary, isModifying, hasSaved } = useStoreState(
    (state) => state.designs.data
  );

  const { userDesignData, currentProjectId } = useStoreState((state) => state.designs.configuration);
  const { setUserDesignData, thunkAutoSave } = useStoreActions((actions) => actions.designs);
  const [currentProject, setCurrentProject] = useState<IProjectSummaryDto | null>(null);
  const navigate: any = useNavigate();
  const location = useLocation();
  const [projectName, setProjectName] = useState<string>("");
  const state = location.state as FrameConfigurationState;
  const { t } = useTranslation();
  const appTitle = useAppTitle();

  useEffect(() => {
    if (state == null || userDesignData == null) {
      navigate("/dashboard", { replace: true });
    } else {
      const newCurrentProject = projectsSummary?.find((project) => project.id === currentProjectId)!;

      setCurrentProject(newCurrentProject);
      setProjectName(newCurrentProject?.name);
    }
  }, []);

  const handleColorClick = (fColor: IFrameColor) => {
    const fColorNumberOfFolds = fColor.numberOfFolds;

    const newInsertDetails = userDesignData?.userCustomDesign.insertDetails;
    if (userDesignData?.numberOfSwitches! > fColorNumberOfFolds) {
      while (newInsertDetails?.length! > fColorNumberOfFolds) newInsertDetails?.pop();

      const newFrameFold = frameFolds.find((ff) => ff.frameCode == fColorNumberOfFolds.toString())!;

      const newValues = {
        ...userDesignData!,
        ...{
          numberOfSwitches: fColorNumberOfFolds,
          userCustomDesign: {
            ...userDesignData?.userCustomDesign!,
            ...{
              frameFold: newFrameFold,
              insertDetails: newInsertDetails!,
              frameColor: fColor,
            },
          },
        },
      };

      setUserDesignData(newValues);
    } else {
      const newValues = {
        ...userDesignData!,
        ...{
          userCustomDesign: {
            ...userDesignData?.userCustomDesign!,
            ...{
              frameColor: fColor,
            },
          },
        },
      };

      setUserDesignData(newValues);
    }
  };

  const handleDecrement = async () => {
    const newNumberOfSwitches = userDesignData?.numberOfSwitches! - 1;
    let newIsHorizontal = userDesignData?.userCustomDesign.isHorizontal;
    if (newNumberOfSwitches >= 1) {
      if (newNumberOfSwitches === 1) {
        newIsHorizontal = true;
      }
      const newFrameFold = frameFolds.find((ff) => ff.frameCode == newNumberOfSwitches.toString())!;

      const newInsertDetails = userDesignData?.userCustomDesign.insertDetails;

      while (newInsertDetails?.length! > newNumberOfSwitches) newInsertDetails?.pop();

      const newValues = {
        ...userDesignData!,
        ...{
          numberOfSwitches: newNumberOfSwitches,
          userCustomDesign: {
            ...userDesignData?.userCustomDesign!,
            ...{
              isHorizontal: newIsHorizontal,
              frameFold: newFrameFold,
              insertDetails: newInsertDetails!,
            },
          },
        },
      };

      setUserDesignData(newValues);
    }
  };

  const handleIncrement = async () => {
    const newNumberOfSwitches = userDesignData?.numberOfSwitches! + 1;
    if (newNumberOfSwitches <= MAX_NUMBER_OF_COVERS) {
      const newFrameFold = frameFolds.find((ff) => ff.frameCode == newNumberOfSwitches.toString())!;

      const newInsertDetails = userDesignData?.userCustomDesign.insertDetails;
      const currentInsertType = newInsertDetails![newInsertDetails?.length! - 1].insertType;
      const currentInsertColor = newInsertDetails![newInsertDetails?.length! - 1].insertColor!;

      while (newInsertDetails?.length! < newNumberOfSwitches) {
        const insertDetail: IInsertDetail = {
          insertType: currentInsertType ?? insertTypes.find((x) => x.name == "Button"),
          insertColor: currentInsertColor,
          order: newNumberOfSwitches,
          rotate: 0,
          items: [],
        };

        newInsertDetails?.push(insertDetail);
      }

      const newValues = {
        ...userDesignData!,
        ...{
          numberOfSwitches: newNumberOfSwitches,
          userCustomDesign: {
            ...userDesignData?.userCustomDesign!,
            ...{
              frameFold: newFrameFold,
              insertDetails: newInsertDetails!,
            },
          },
        },
      };

      setUserDesignData(newValues);
    }
  };

  const handleOrientation = async (isHorizontal: boolean) => {
    const newValues = {
      ...userDesignData!,
      ...{
        userCustomDesign: {
          ...userDesignData?.userCustomDesign!,
          ...{
            isHorizontal: isHorizontal,
          },
        },
      },
    };

    setUserDesignData(newValues);
  };

  const handleGoToInsertConfiguration = async () => {
    const newValues = {
      ...userDesignData!,
      ...{
        userCustomDesign: {
          ...userDesignData?.userCustomDesign!,
          ...{
            pathname: "/insert-configuration",
          },
        },
      },
    };

    setUserDesignData(newValues);
    await thunkAutoSave(newValues);

    navigate("/insert-configuration", {
      state: {
        selectedCoverNumber: 0,
        isDesignSelected: true,
      },
    });
  };

  const confirmBackToSwitchSelection = () => {
    if (isModifying) {
      designRangeConfirmationDialogRef.current?.open();
      return;
    }

    handleBackToSwitchSelection();
  };

  const handleBackToSwitchSelection = () => {
    navigate("/start-configurator", {
      state: { isNewProject: false },
    });
  };

  return (
    <ExternalLayout
      showNavbarCenteredText={false}
      showNavbarLinks={true}
      showPageHeader={false}
      pageHeaderTextTranslationKey="welcomeToBuschJaegerCustomisationCenter"
      navbarLogoLink="/"
      navbarMainTitle={appTitle}
      navbarBackGroundColor="#fff"
      bodyBackGroundColor="#fff"
    >
      {(userDesignData == null && <Spinner />) || (
        <>
          <div className="frameConfiguration">
            <div className="frameConfiguration__main">
              <div className="frameConfiguration__headerContainer">
                <div className="frameConfiguration__headerContainerButtons">
                  <Button
                    className="frameConfiguration__backToSwitchSelectionBtn"
                    variant="text"
                    onClick={confirmBackToSwitchSelection}
                    startIcon={<ChevronLeftOutlinedIcon sx={{ width: "28px", height: "28px" }} />}
                  >
                    <span>{t("frame_configuration.button.back_switch_selection")}</span>
                  </Button>
                  <Typography
                    variant="body1"
                    fontSize="16px"
                    fontWeight={400}
                    color="#696969"
                  >
                    {t("frame_configuration.label.project_name")}
                    <span style={{ color: "#000" }}> {projectName}</span>
                    <EditButton editDialogRef={editDialogRef} />
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div className="frameConfiguration__mainContainer">
            <div className="frameConfiguration__mainPreview">
              <div
                className={
                  userDesignData?.userCustomDesign.isHorizontal
                    ? "frameConfiguration__mainPreviewTitle-horizontal"
                    : "frameConfiguration__mainPreviewTitle-vertical"
                }
              >
                <Typography
                  variant="body1"
                  fontSize="16px"
                  fontWeight={400}
                >
                  {t("frame_configuration.label.selected_design_range")}
                </Typography>

                <Typography
                  variant="body1"
                  fontSize="24px"
                  fontWeight={500}
                >
                  {userDesignData?.design?.name}
                </Typography>

                <Button
                  className="frameConfiguration__changeBtn"
                  variant="text"
                  onClick={confirmBackToSwitchSelection}
                >
                  {t("frame_configuration.button.change")}
                </Button>
              </div>
              <div
                className={`frameConfiguration__mainPreviewContent frameConfiguration__mainPreviewContent${userDesignData?.userCustomDesign.code}`}
              >
                <div
                  className={
                    userDesignData?.userCustomDesign.isHorizontal
                      ? `frameConfiguration__mainPreviewImage--horizontal frameConfiguration__mainPreviewImage-${userDesignData?.numberOfSwitches!}--horizontal`
                      : `frameConfiguration__mainPreviewImage--vertical frameConfiguration__mainPreviewImage-${userDesignData?.numberOfSwitches!}--vertical`
                  }
                >
                  <Frame
                    numberOfSwitches={userDesignData?.numberOfSwitches!}
                    isHorizontal={userDesignData?.userCustomDesign.isHorizontal!}
                    design={userDesignData?.design!}
                    frameColor={userDesignData?.userCustomDesign.frameColor!}
                    enableSelection={false}
                    enableTransition={false}
                    showMessageOnImageNotFound={true}
                  >
                    {userDesignData?.userCustomDesign.insertDetails.map((detail, coverIndex) => (
                      <Cover
                        key={coverIndex}
                        cover={coverIndex}
                        insertDetail={detail}
                        designCode={userDesignData?.design?.code!}
                        isHorizontal={userDesignData?.userCustomDesign?.isHorizontal!}
                      />
                    ))}
                  </Frame>
                </div>
              </div>
            </div>

            <div
              className={`frameConfiguration__mainParameters ${
                userDesignData?.numberOfSwitches! > NUMBER_OF_SINGLE_COVER ? "with-margin-top" : ""
              }`}
            >
              <div className="frameConfiguration__mainParametersTitle">
                <Typography
                  fontSize={32}
                  fontWeight={700}
                  variant="h4"
                >
                  {t("frame_configuration.label.title")}
                </Typography>
              </div>

              <div className="frameConfiguration__mainParametersDivider"></div>

              <div className="frameConfiguration__mainParameterFrameColor">
                <Tooltip
                  placement="top"
                  title={t("frame_configuration.frame_color.tooltip")}
                  slotProps={{
                    tooltip: {
                      style: {
                        fontSize: "12px",
                        fontWeight: 400,
                        padding: "8px 12px",
                      },
                      width: "265px",
                      height: "58px",
                    },
                  }}
                >
                  <Typography
                    variant="h4"
                    fontSize={21}
                    fontWeight={700}
                  >
                    {t("frame_configuration.label.frame_color")}
                    <QuestionMark2
                      fill={ftsTheme.icon.color}
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      style={{ marginLeft: "5px" }}
                    />
                  </Typography>
                </Tooltip>

                <Typography
                  variant="body1"
                  fontSize="16px"
                  fontWeight={400}
                >
                  {t("frame_configuration.label.choose_your_frame_color")}
                </Typography>

                <FrameColorContainer handleColorClick={handleColorClick} />
              </div>

              <div className="frameConfiguration__mainParameterSwitches">
                <Typography
                  variant="h4"
                  fontSize="21px"
                  fontWeight={700}
                >
                  {t("frame_configuration.label.how_many_switches")}
                </Typography>
                <Typography
                  variant="body1"
                  fontSize="16px"
                  fontWeight={400}
                >
                  {t("frame_configuration.label.number_of_switches_in_a_row")}
                </Typography>

                <div className="frameConfiguration__mainParameterSwitchesCounter">
                  <HowManySwitchesCounter
                    numberOfSwitches={userDesignData?.numberOfSwitches!}
                    handleDecrement={handleDecrement}
                    handleIncrement={handleIncrement}
                    disabledDecrement={userDesignData?.numberOfSwitches! == NUMBER_OF_SINGLE_COVER}
                    disabledIncrement={
                      userDesignData?.numberOfSwitches! === MAX_NUMBER_OF_COVERS ||
                      userDesignData?.numberOfSwitches! === userDesignData?.userCustomDesign.frameColor?.numberOfFolds
                    }
                    fillIconDecrement={
                      userDesignData?.numberOfSwitches! == NUMBER_OF_SINGLE_COVER
                        ? "#BABABA"
                        : theme.palette.primary.main
                    }
                    fillIconIncrement={
                      userDesignData?.numberOfSwitches! === userDesignData?.userCustomDesign.frameColor?.numberOfFolds
                        ? "#BABABA"
                        : ftsTheme.icon.color
                    }
                  />
                </div>

                <div className="frameConfiguration__mainParameterSwitchesOrientation">
                  {userDesignData?.numberOfSwitches! > NUMBER_OF_SINGLE_COVER && (
                    <>
                      <Typography
                        variant="body1"
                        fontSize="16px"
                        fontWeight={400}
                      >
                        {t("frame_configuration.label.orientation")}
                      </Typography>

                      <div className="frameConfiguration__mainParameterSwitchesOrientationButtons">
                        <Button
                          sx={
                            userDesignData?.userCustomDesign.isHorizontal == true
                              ? {
                                  width: "48px",
                                  height: "48px",
                                  minWidth: "unset",
                                  padding: "5px",
                                  borderRadius: "0px",
                                  borderColor: theme.palette.primary.main,
                                }
                              : {
                                  width: "48px",
                                  height: "48px",
                                  minWidth: "unset",
                                  padding: "5px",
                                  borderRadius: "0px",
                                  borderColor: "#BABABA",
                                }
                          }
                          variant="outlined"
                          onClick={() => handleOrientation(true)}
                        >
                          <RectangleHorizontal />
                        </Button>
                        <Button
                          sx={
                            userDesignData?.userCustomDesign.isHorizontal == false
                              ? {
                                  width: "48px",
                                  height: "48px",
                                  minWidth: "unset",
                                  padding: "5px",
                                  borderRadius: "0px",
                                  borderColor: theme.palette.primary.main,
                                }
                              : {
                                  width: "48px",
                                  height: "48px",
                                  minWidth: "unset",
                                  padding: "5px",
                                  borderRadius: "0px",
                                  borderColor: "#BABABA",
                                }
                          }
                          variant="outlined"
                          onClick={() => handleOrientation(false)}
                        >
                          <RectangleVertical />
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="frameConfiguration__mainParameterInfo">
                <InfoSign fill={ftsTheme.icon.color} />
                <Typography
                  ml={1}
                  variant="body1"
                  fontSize="14px"
                  fontWeight={400}
                >
                  {t("frame_configuration.label.frame_is_added_for_preview_purpose_only")} <br />
                  {t("frame_configuration.label.the_frame_should_be_bought_separately")}
                </Typography>
              </div>

              <div className="frameConfiguration__mainParametersDivider"></div>

              <div className="frameConfiguration__mainParametersNextButton">
                <NextButton handleGoToInsertConfiguration={handleGoToInsertConfiguration} />
              </div>
            </div>
          </div>

          <div className="frameConfiguration__needHelpDiv">
            <div className="frameConfiguration__needHelpDivInside">
              <HelpCustomizationButton />
            </div>
          </div>

          <DesignRangeConfirmationDialog
            ref={designRangeConfirmationDialogRef}
            handleConfirm={handleBackToSwitchSelection}
          />
          <EditDialog
            ref={editDialogRef}
            name={projectName}
            projectId={currentProjectId!}
            setProjectName={setProjectName}
          />
        </>
      )}
    </ExternalLayout>
  );
};

export default FrameConfiguration;
