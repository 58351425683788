import { IResult } from "../interfaces/IResult";
import { Status } from "../models/status";

export const handleSuccess = <T>(message?: string, data?: T) => {
  const status = Status.COMPLETED;
  const result: IResult<T> = {
    status: status,
    message: message || "",
    data: data,
  };

  return result;
};
