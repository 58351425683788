import React, { createRef, useEffect, useState } from "react";
import { useStoreActions } from "../../../../hooks";
import { IDesign } from "../../../../interfaces/IDesign";
import { FrameStyleProps } from "../../../../interfaces/IFrameAndCoverStyle";
import { IFrameColor } from "../../../../interfaces/IFrameColor";
import "./Frame.css";
import FrameUtils from "./frame-utils";
import { useTranslation } from "react-i18next";

interface IFrameInputProps {
  children: React.ReactNode;
  numberOfSwitches: number;
  isHorizontal: boolean;
  frameColor: IFrameColor;
  design: IDesign;
  enableSelection: boolean;
  selectedCoverNumber?: number;
  onPreviousCallback?: Function;
  onNextCallback?: Function;
  enableTransition?: boolean;
  showMessageOnImageNotFound?: boolean;
  disableNavigationButtons?: boolean;
}

export const framePrevButton = createRef<HTMLDivElement>();
export const frameNextButton = createRef<HTMLDivElement>();

const Frame: React.FC<IFrameInputProps> = (props) => {
  const { thunkOpenSnackBar } = useStoreActions((actions) => actions.app);

  const {
    numberOfSwitches,
    isHorizontal,
    frameColor,
    design,
    enableSelection,
    enableTransition,
    selectedCoverNumber,
    onNextCallback,
    onPreviousCallback,
    showMessageOnImageNotFound,
    disableNavigationButtons,
    children,
  } = props;
  const [length, setLength] = useState(numberOfSwitches);

  const { t } = useTranslation();

  const setFrameScale = (numberOfSwitches: number) => {
    if (enableSelection) return 1;

    if (isHorizontal) return numberOfSwitches == 1 ? numberOfSwitches / 1 : numberOfSwitches / 2;

    return numberOfSwitches == 1 ? numberOfSwitches / 0.8 : numberOfSwitches / 1.5;
  };

  useEffect(() => {
    setLength(numberOfSwitches);
  }, [numberOfSwitches]);

  const next = () => {
    if (disableNavigationButtons) return;

    if (selectedCoverNumber! < length - 1) {
      if (onNextCallback) onNextCallback();
    }
  };

  const prev = () => {
    if (disableNavigationButtons) return;

    if (selectedCoverNumber! > 0) {
      if (onPreviousCallback) onPreviousCallback();
    }
  };

  const getMarginValues = () => {
    return enableSelection ? "calc(300px - 75px) !important" : "0";
  };

  const getTransformValues = () => {
    let value = 260;

    if (FrameStyleProps.get(design.code)?.cover.size == 70) {
      value = 275;
    }

    if (FrameStyleProps.get(design.code)?.cover.size == 59) {
      value = 220;
    }

    if (enableSelection) {
      return `translateX(-${selectedCoverNumber! * value}px) scale(${1 / setFrameScale(numberOfSwitches)}`;
    } else return `scale(${0.9 / setFrameScale(numberOfSwitches)}`;
  };

  const getBackgroundUrl = () => {
    const source = `${process.env.REACT_APP_BLOB_STORAGE_URL}/images/frames/${design.code}/${frameColor.code}/f_${numberOfSwitches}.png`;

    var img = new Image();
    img.src = source;
    img.onerror = () => {
      if (showMessageOnImageNotFound!) {
        thunkOpenSnackBar({
          message: t("frame.get_background_url.message"),
          severity: "info",
        });
        return;
      }
    };

    return `url(${source})`;
  };

  return (
    <>
      <div className="frame-container">
        <div className={`frame-wrapper ${!isHorizontal ? "frame-wrapper-vertical" : ""}`}>
          {enableSelection && selectedCoverNumber! > 0 && (
            <div
              ref={framePrevButton}
              className={`left-arrow ${!isHorizontal ? "left-arrow-vertical" : ""}`}
              onClick={prev}
            ></div>
          )}

          <div className={`frame-content-wrapper ${enableSelection ? "frame-content-wrapper-enable-selection" : ""}`}>
            <div
              className={`
                ${isHorizontal ? "shadow" : "shadow-vertical"}
                frame-content 
                frame-content-${FrameStyleProps.get(design.code)?.cover.size}-${numberOfSwitches}-fold
                ${enableTransition ? "frame-content-transition" : ""}
                ${
                  enableSelection
                    ? isHorizontal
                      ? `frame-content-enable-selection-horizontal-${FrameStyleProps.get(design.code)?.cover.size}-${numberOfSwitches}-fold`
                      : "frame-content-enable-selection-vertical"
                    : ""
                }
                `}
              style={{
                marginLeft: getMarginValues(),
                transform: getTransformValues(),
                backgroundSize: FrameUtils.getBackgroundSize(
                  numberOfSwitches,
                  FrameStyleProps.get(design.code)?.cover.size!
                ),
                backgroundImage: getBackgroundUrl(),
                padding: FrameStyleProps.get(design.code)?.frame.padding,
              }}
            >
              {children}
            </div>
          </div>

          {enableSelection && selectedCoverNumber! < numberOfSwitches - 1 && (
            <div
              ref={frameNextButton}
              className={`right-arrow ${!isHorizontal ? "right-arrow-vertical" : ""}`}
              onClick={next}
            ></div>
          )}
        </div>
      </div>
    </>
  );
};

export default Frame;
