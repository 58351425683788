import Button from "@mui/material/Button";
import { FC, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { getAuthorizeHref } from "../../auth/config";
import { useAuth } from "../../auth/utils";
import Spinner from "../../components/Spinner/Spinner";
import "./Login.css";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { FTSTheme } from "../../App";

const Login: FC<{}> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme() as FTSTheme;

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { isAuthenticated, actions: auth } = useAuth();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  useAsyncEffect(async () => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }

    if (code) {
      setIsLoading(true);
      await auth.authenticate(code);
      setIsLoading(false);
    }
  }, []);

  return (
    <>
      {!isAuthenticated && isLoading && <Spinner />}
      {!isAuthenticated && !isLoading && (
        <div className="login">
          <div className="formContainer">
            <div className={`formContainer__login`}>
              {theme.name == "bje" && (
                <img
                  src="/logo.svg"
                  alt="logo"
                  className={`login_logo_${process.env.REACT_APP_THEME}`}
                />
              )}
              {theme.name == "abb" && (
                <img
                  src="/abb-logo.svg"
                  alt="logo"
                  className={`login_logo_${process.env.REACT_APP_THEME}`}
                />
              )}
              <Typography variant="h1">{t("login.label.sign_in")}</Typography>
              <Button
                className="formContainer__btn"
                variant="contained"
                onClick={() => window.open(getAuthorizeHref(), "_self")}
              >
                <Typography>{t(`${theme.name}_login.button.signin`)}</Typography>
              </Button>

              <Typography className="formContainer__text">
                {t("login.link.not_an_existing_user")}{" "}
                <a
                  href={process.env.REACT_APP_REGISTER_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("login.link.register")}
                </a>
              </Typography>
            </div>
            {/* <button className="formContainer__guestBtn">
              <Typography>{t("login.button.continue_as_guest")}</Typography>
            </button> */}
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
