import { Trans, useTranslation } from "react-i18next";
import { Button, Grid, Typography } from "@mui/material";
import InfoIcon from "../../Icons/Info2.svg";
import { ReactSVG } from "react-svg";
import { useTheme } from "@emotion/react";
import { FTSTheme } from "../../../../App";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "../../Icons/download.svg";
import { downloadProjectDialogRef } from "../Download/DownloadProjectDialog";
import ArrowRightIcon from "../../Icons/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import FitText from "../../../../components/FitText/FitText";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useStoreState } from "../../../../hooks";

interface IOrderSummaryProps {
  refreshProductDesign: () => Promise<void>;
}

const WithNoElBridge: React.FC<IOrderSummaryProps> = ({ refreshProductDesign }) => {
  const { t } = useTranslation();
  const ftsTheme = useTheme() as FTSTheme;
  const navigate: any = useNavigate();
  const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState(true);
  const { productDesigns } = useStoreState((state) => state.designs.data);

  useEffect(() => {
    setIsDownloadButtonDisabled(isEmpty(productDesigns));
  }, [productDesigns]);

  const handleOpenMyProjects = async () => {
    navigate("/projects", {
      state: {
        isDesignSelected: true,
      },
    });
  };

  return (
    <>
      <div className="designSummary__mainSummary">
        <div className="designSummary__mainSummaryTitle">
          <Typography
            variant="h4"
            fontSize="18px"
            fontWeight={700}
          >
            {t("DesignSummary_OrderSummary")}
          </Typography>
        </div>

        <Grid
          container
          md={12}
          mt={1}
        >
          <Typography
            variant="h4"
            fontSize="14px"
            fontWeight={400}
          >
            {t("DesignSummary_WithNoElBridge_MainDescription")}
          </Typography>
        </Grid>

        <Grid
          container
          md={12}
        >
          <div className={`${ftsTheme.name}_designSummary__container_info`}>
            <div>
              <ReactSVG
                src={InfoIcon}
                beforeInjection={(svg) => {
                  svg.setAttribute(
                    "style",
                    `width: 16px; height: 16px; fill: ${ftsTheme.icon.color}; padding: 0px; margin-top:5px`
                  );
                }}
              />
            </div>
            <div className="designSummary__info">
              <Typography
                variant="body1"
                fontSize="12px"
                fontWeight={400}
              >
                <Trans
                  i18nKey="DesignSummary_WithNoElBridge_Info"
                  components={{ bold: <strong /> }}
                />
              </Typography>
            </div>
          </div>
        </Grid>

        <div className="designSummary__mainSummaryDivider"></div>

        <div className={`designSummary__mainSummaryButtons`}>
          <LoadingButton
            onClick={() => downloadProjectDialogRef.current?.open()}
            variant="contained"
            disabled={isDownloadButtonDisabled}
            endIcon={
              <ReactSVG
                src={DownloadIcon}
                beforeInjection={(svg) => {
                  svg.setAttribute("style", `width: 16px; height: 16px; color: ${ftsTheme.icon.color}; fill: white`);
                }}
              />
            }
          >
            <FitText>{t("DesignSummary_DownloadProject")}</FitText>
          </LoadingButton>
          <Button
            sx={{
              width: 387,
              height: 56,
              background: "#fff",
              padding: 24,
              top: 10,
              border: 2,
              borderColor: "#DBDBDB",
            }}
            onClick={() => handleOpenMyProjects()}
            endIcon={
              <ReactSVG
                src={ArrowRightIcon}
                beforeInjection={(svg) => {
                  svg.setAttribute(
                    "style",
                    `width: 16px; height: 16px; color: ${ftsTheme.icon.color}; fill: ${ftsTheme.icon.color}}`
                  );
                }}
              />
            }
          >
            <FitText sx={{ fontWeight: 500, color: ftsTheme.icon.color, lineHeight: "22.25px" }}>
              {t("buttons.go_to_my_projects")}
            </FitText>
          </Button>
        </div>
      </div>
    </>
  );
};

export default WithNoElBridge;
