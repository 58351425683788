import { Box, Button, ButtonProps, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../../App";
import { ABB, BJE } from "../../../constants/Theme";
import FitText from "../../../components/FitText/FitText";
import { Favorite as FavoriteIcon } from "@mui/icons-material";

interface IButtonProps extends ButtonProps {
  handleOpenFavoriteDesignsTable: () => void;
}

const AbbCoverControl: React.FC<IButtonProps> = ({ handleOpenFavoriteDesignsTable }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box className="insertConfiguration__btnUseFavoriteDesign_container">
      <Button
        variant="outlined"
        onClick={handleOpenFavoriteDesignsTable}
        className="insertConfiguration__btnUseFavoriteDesign"
        startIcon={
          <FavoriteIcon
            className="insertConfiguration__favoriteIcon"
            style={{ color: theme.palette.primary.main }}
          />
        }
      >
        <FitText>{t("favoriteDesigns.button.use_design")}</FitText>
      </Button>
    </Box>
  );
};

const BjeCoverControl: React.FC<IButtonProps> = ({ handleOpenFavoriteDesignsTable }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box className="insertConfiguration__btnUseFavoriteDesign_container">
      <Button
        variant="outlined"
        onClick={handleOpenFavoriteDesignsTable}
        className="insertConfiguration__btnUseFavoriteDesign"
        startIcon={
          <FavoriteIcon
            className="insertConfiguration__favoriteIcon"
            style={{ color: theme.palette.primary.main }}
          />
        }
      >
        <FitText>{t("favoriteDesigns.button.use_design")}</FitText>
      </Button>
    </Box>
  );
};

const CoverControlButton: React.FC<IButtonProps> = ({ handleOpenFavoriteDesignsTable }) => {
  const theme = useTheme() as FTSTheme;

  return (
    <>
      {(theme.name == ABB && <AbbCoverControl handleOpenFavoriteDesignsTable={handleOpenFavoriteDesignsTable} />) ||
        (theme.name == BJE && <BjeCoverControl handleOpenFavoriteDesignsTable={handleOpenFavoriteDesignsTable} />)}
    </>
  );
};

export default CoverControlButton;
