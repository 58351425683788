import { Button, ButtonProps, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../../../../App";
import { ABB, BJE } from "../../../../../constants/Theme";
import { Link } from "react-router-dom";
import FitText from "../../../../../components/FitText/FitText";
import { DASHBOARD_PATH, FAVORITE_DESIGNS_PATH } from "../../../../../constants/Paths";

interface IButtonProps extends ButtonProps {
  loading: boolean;
  isFavoriteDesignPage: boolean;
  handleClose: () => void;
}

const AbbBackButton: React.FC<IButtonProps> = ({ loading, isFavoriteDesignPage, handleClose }) => {
  const { t } = useTranslation();
  const theme = useTheme() as FTSTheme;

  return (
    <Link to={isFavoriteDesignPage ? FAVORITE_DESIGNS_PATH : DASHBOARD_PATH}>
      <Button
        sx={{
          width: 96,
          height: 40,
          display: "flex",
        }}
        variant="text"
        disabled={loading}
        onClick={handleClose}
      >
        <FitText sx={{ fontSize: "14px", fontWeight: 500 }}>
          {t(`${theme.name}.configurator_welcome_dialog.button.back.title`)}
        </FitText>
      </Button>
    </Link>
  );
};

const BjeBackButton: React.FC<IButtonProps> = ({ loading, isFavoriteDesignPage, handleClose }) => {
  const { t } = useTranslation();
  const theme = useTheme() as FTSTheme;

  return (
    <Link to={isFavoriteDesignPage ? FAVORITE_DESIGNS_PATH : DASHBOARD_PATH}>
      <Button
        sx={{ width: 193, height: 41 }}
        variant="text"
        disabled={loading}
        style={{ textTransform: "unset" }}
        onClick={handleClose}
      >
        <FitText>{t(`${theme.name}.configurator_welcome_dialog.button.back.title`)}</FitText>
      </Button>
    </Link>
  );
};

const BackButton: React.FC<IButtonProps> = ({ loading, isFavoriteDesignPage, handleClose }) => {
  const theme = useTheme() as FTSTheme;

  return (
    <>
      {(theme.name == ABB && (
        <AbbBackButton
          loading={loading}
          isFavoriteDesignPage={isFavoriteDesignPage}
          handleClose={handleClose}
        />
      )) ||
        (theme.name == BJE && (
          <BjeBackButton
            loading={loading}
            isFavoriteDesignPage={isFavoriteDesignPage}
            handleClose={handleClose}
          />
        ))}
    </>
  );
};

export default BackButton;
