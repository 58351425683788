import { Trans, useTranslation } from "react-i18next";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import InfoIcon from "../../Icons/Info2.svg";
import { useStoreState } from "../../../../hooks";
import FindDistributorDialog, { findDistributorDialogRef } from "../FindDistributor/FindDistributorDialog";
import { EDesignStatus } from "../../../../enum/EDesignStatus";
import { ReactSVG } from "react-svg";
import FindDistributorButton from "../FindDistributorButton";
import { useTheme } from "@emotion/react";
import { FTSTheme } from "../../../../App";
import { LoadingButton } from "@mui/lab";
import ArrowRightIcon from "../../Icons/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import FitText from "../../../../components/FitText/FitText";

interface IOrderSummaryProps {
  refreshProductDesign: () => Promise<void>;
}

const WithElBridge: React.FC<IOrderSummaryProps> = ({ refreshProductDesign }) => {
  const { productDesignSummary, fetchingData } = useStoreState((state) => state.designs.data);
  const { t } = useTranslation();
  const ftsTheme = useTheme() as FTSTheme;
  const navigate: any = useNavigate();
  const handleOpenFindDistributor = async () => {
    await refreshProductDesign();
    findDistributorDialogRef.current?.open();
  };

  const handleOpenMyProjects = async () => {
    navigate("/projects", {
      state: {
        isDesignSelected: true,
      },
    });
  };

  return (
    <>
      <div className="designSummary__mainSummary">
        <div className="designSummary__mainSummaryTitle">
          <Typography
            variant="h4"
            fontSize="18px"
            fontWeight={700}
          >
            {t("DesignSummary_OrderSummary")}
          </Typography>
        </div>

        <Grid
          container
          md={12}
          mt={1}
        >
          <Typography
            variant="h4"
            fontSize="12px"
            fontWeight={400}
          >
            {t("DesignSummary_MainDescription")}
          </Typography>
        </Grid>

        <Grid
          container
          md={12}
        >
          <div className={`${ftsTheme.name}_designSummary__container_info`}>
            <div>
              <ReactSVG
                src={InfoIcon}
                beforeInjection={(svg) => {
                  svg.setAttribute(
                    "style",
                    `width: 16px; height: 16px; fill: ${ftsTheme.icon.color}; padding: 0px; margin-top:5px`
                  );
                }}
              />
            </div>
            <div className="designSummary__info">
              <Typography
                variant="body1"
                fontSize="12px"
                fontWeight={400}
              >
                <Trans
                  i18nKey="DesignSummary_Info"
                  components={{ bold: <strong /> }}
                />
              </Typography>
            </div>
          </div>
        </Grid>

        <div className="designSummary__mainSummaryDivider"></div>

        <div className={`designSummary__mainSummaryButtons`}>
          <FindDistributorButton
            loading={fetchingData}
            disabled={
              productDesignSummary.map((x) => x.designStatus).includes(EDesignStatus.Draft) ||
              productDesignSummary.length === 0
            }
            handleOpenFindDistributor={handleOpenFindDistributor}
          />
          <Button
            sx={{
              width: 387,
              height: 56,
              background: "#fff",
              padding: 24,
              top: 10,
              border: 2,
              borderColor: "#DBDBDB",
            }}
            onClick={() => handleOpenMyProjects()}
            endIcon={
              <ReactSVG
                src={ArrowRightIcon}
                beforeInjection={(svg) => {
                  svg.setAttribute(
                    "style",
                    `width: 16px; height: 16px; color: ${ftsTheme.icon.color}; fill: ${ftsTheme.icon.color}`
                  );
                }}
              />
            }
          >
            <FitText sx={{ fontWeight: "500", color: ftsTheme.icon.color, lineHeight: "22.25px" }}>
              {t("buttons.go_to_my_projects")}
            </FitText>
          </Button>
        </div>
      </div>
      {!fetchingData && (
        <FindDistributorDialog
          elBridge={productDesignSummary.flatMap((x) => x.elBridge)}
          ref={findDistributorDialogRef}
        />
      )}
    </>
  );
};

export default WithElBridge;
