import { useTranslation } from "react-i18next";
import { useStoreActions, useStoreState } from "../../../../hooks";
import { EInsertDetailItemType, IInsertDetail } from "../../../../interfaces/IInsertDetail";
import { debounce, executeWithDebounce } from "../../../../utils/debounce";
import EraseIcon from "../../Icons/erase.svg";
import RotateIcon from "../../Icons/rotate.svg";
import FlipIcon from "../../Icons/FlipIcon.svg";
import { tileRefs } from "../../InsertConfiguration/InsertConfiguration";
import { ICanvasForwardProps } from "../../InsertConfiguration/Tiles/Canvas";
import CoverActionsMenu from "./CoverActionsMenu";
import { ReactSVG } from "react-svg";
import { Typography } from "@mui/material";
import CoverItemsRefsFunctions from "../../InsertConfiguration/Tiles/utils-cover-items-refs";
import CoverItemsFunctions from "../../InsertConfiguration/Tiles/utils-cover-items";
import { EIconPercentSize } from "../../../../enum/EIconSize";

interface ICoverActions {
  selectedCoverNumber: number;
  setSelectedCoverNumber: Function;
  setInsertDetail: (detail: IInsertDetail) => void;
}

const CoverActions: React.FC<ICoverActions> = (props) => {
  const { t } = useTranslation();
  const { thunkOpenConfirm, thunkCloseConfirm } = useStoreActions((actions) => actions.app);

  const { userDesignData } = useStoreState((state: any) => state.designs.configuration);

  const { insertTypes } = useStoreState((state) => state.designs.data);

  const { setUserDesignData, thunkAutoSave, setFetchingCover } = useStoreActions((actions: any) => actions.designs);
  const { designSizes } = useStoreState((state) => state.designs.data);

  const handleRotate = () => {
    if (!allowRotate()) return;

    setFetchingCover(true);

    const newInsertDetails = userDesignData?.userCustomDesign.insertDetails.map(
      (detail: IInsertDetail, index: number) => {
        if (props.selectedCoverNumber == index) {
          let rotate = detail.rotate ?? 0;
          rotate = rotate == 270 ? 0 : rotate + 90;

          //refresh canvas for each tile inside cover
          let newDetail = detail;
          const tileRefsSelectedCover = tileRefs[props.selectedCoverNumber] as React.RefObject<ICanvasForwardProps>[];
          for (var property in tileRefsSelectedCover) {
            newDetail = tileRefsSelectedCover[property].current?.refresh(rotate, newDetail);
          }

          return { ...newDetail, rotate: rotate };
        }

        return detail;
      }
    );

    const newValues = {
      ...userDesignData!,
      ...{
        userCustomDesign: {
          ...userDesignData?.userCustomDesign!,
          ...{
            insertDetails: newInsertDetails!,
          },
        },
      },
    };

    const insertDetailRotated = newInsertDetails[props.selectedCoverNumber];
    setUserDesignData(newValues);
    executeWithDebounce(
      () => {},
      newValues,
      1000,
      () => {
        updateIconAndTextPositions(insertDetailRotated, props.selectedCoverNumber);
        setFetchingCover(false);
      }
    );
  };

  const updateIconAndTextPositions = (insertDetail: IInsertDetail, coverIndex: number) => {
    insertDetail.items.forEach(async (item) => {
      if (item.type == EInsertDetailItemType.Icon) {
        await CoverItemsFunctions.addOrUpdateIcon({
          id: item.id,
          iconId: item.iconId!,
          color: item.color,
          indexDetail: item.indexDetail,
          coverIndex: coverIndex,
          iconName: item.iconName!,
          materialNumber: item.materialNumber!,
          insertDetail: insertDetail,
          setInsertDetail: props.setInsertDetail,
          designSizes: designSizes,
          iconSizePercentage: EIconPercentSize.MEDIUM,
        });
      }

      if (item.type == EInsertDetailItemType.Text) {
        await CoverItemsFunctions.addOrUpdateText({
          id: item.id,
          color: item.color,
          indexDetail: item.indexDetail,
          coverIndex: coverIndex,
          insertDetail: insertDetail,
          name: item.font?.name!,
          style: item.font?.style!,
          weight: item.font?.weight!,
          lineSpacing: 1,
          size: item.font?.size!,
          textValue: item.textValue!,
          setInsertDetail: props.setInsertDetail,
          designSizes: designSizes,
        });
      }
    });
  };

  const handleEraseConfirm = () => {
    if (!allowErase()) return;

    thunkOpenConfirm({
      title: t("InsertConfiguration_EraseDialog_Title"),
      message: t("InsertConfiguration_EraseDialog_Message"),
      onDismiss: {
        text: t("InsertConfiguration_EraseDialog_CancelButton"),
      },
      onConfirm: {
        text: t("InsertConfiguration_EraseDialog_ConfirmButton"),
        onClick: () => handleErase(),
      },
      useLoading: true,
    });
  };

  const handleErase = async () => {
    setFetchingCover(true);
    const insertType = insertTypes.find((it) => it.name == "Button")!;

    const newInsertDetails = userDesignData?.userCustomDesign.insertDetails.map((detail, index) => {
      if (props.selectedCoverNumber == index) {
        const newDetail = { ...detail, insertType: insertType };

        //clear the props, when erase the cover
        Object.keys(newDetail)
          .filter((key) => !["id", "order", "insertColor", "insertType", "coverDesignId", "hasGridLine"].includes(key))
          .forEach((key) => {
            (newDetail as any)[key] = null;

            if (key == "rotate") {
              (newDetail as any)[key] = 0;
            }

            if (key == "isFlipped") {
              (newDetail as any)[key] = false;
            }

            if (key == "items") {
              (newDetail as any)[key] = [];
            }
          });

        //refresh tile's canvas
        const tileRefsSelectedCover = tileRefs[props.selectedCoverNumber] as React.RefObject<ICanvasForwardProps>[];
        for (var property in tileRefsSelectedCover) {
          tileRefsSelectedCover[property].current?.refresh(0, newDetail, true);
        }

        return newDetail;
      }

      return detail;
    });

    CoverItemsRefsFunctions.removeAllRefsByCover(props.selectedCoverNumber);
    CoverItemsFunctions.removeAllByInsertDetailId(
      userDesignData?.userCustomDesign.insertDetails[props.selectedCoverNumber].id!
    );

    const newValues = {
      ...userDesignData!,
      ...{
        userCustomDesign: {
          ...userDesignData?.userCustomDesign!,
          ...{
            insertDetails: newInsertDetails!,
          },
        },
      },
    };

    setUserDesignData(newValues);

    await thunkAutoSave(newValues);
    thunkCloseConfirm();
  };

  const handleFlip = async () => {
    if (!allowFlip()) return;

    const newInsertDetails = userDesignData?.userCustomDesign.insertDetails.map(
      (detail: IInsertDetail, index: number) => {
        if (props.selectedCoverNumber == index) {
          let newDetail = detail;
          return { ...newDetail, isFlipped: !detail.isFlipped };
        }

        return detail;
      }
    );

    const newValues = {
      ...userDesignData!,
      ...{
        userCustomDesign: {
          ...userDesignData?.userCustomDesign!,
          ...{
            insertDetails: newInsertDetails!,
          },
        },
      },
    };

    setUserDesignData(newValues);
  };

  const allowRotate = () => {
    const insertType = userDesignData?.userCustomDesign.insertDetails[props.selectedCoverNumber]?.insertType!;
    return insertType?.allowRotate ?? true;
  };

  const allowFlip = () => {
    const insertType = userDesignData?.userCustomDesign.insertDetails[props.selectedCoverNumber]?.insertType!;
    return insertType?.allowFlip ?? true;
  };

  const allowErase = () => {
    let canErase = false;
    userDesignData?.userCustomDesign.insertDetails.forEach((detail, index) => {
      if (props.selectedCoverNumber == index) {
        if (detail.insertType.name != "Button") {
          canErase = true;
          return;
        }

        Object.keys(detail)
          .filter(
            (key) =>
              !["id", "order", "insertColor", "rotate", "insertType", "coverDesignId", "hasGridLine"].includes(key)
          )
          .forEach((key) => {
            if ((detail as any)[key] != null) {
              canErase = true;
              return;
            }
          });
      }
    });

    return canErase;
  };

  return (
    <div
      className={`${
        userDesignData?.userCustomDesign.isHorizontal!
          ? "insertConfiguration__optionsContainer"
          : "insertConfiguration__optionsContainer--vertical"
      }`}
    >
      <div
        onClick={handleFlip}
        className={`
          ${
            userDesignData?.userCustomDesign.isHorizontal!
              ? "insertConfiguration__option"
              : "insertConfiguration__option--vertical"
          }
          ${allowFlip() ? "" : "insertConfiguration__optionDisabled"}`}
      >
        <ReactSVG src={FlipIcon} />
        <Typography variant="caption">{t("InsertConfiguration_ActionButtons_FlipButton")}</Typography>
      </div>
      <div className="insertConfiguration__optionSeparator"></div>
      <div
        onClick={handleRotate}
        className={`
          ${
            userDesignData?.userCustomDesign.isHorizontal!
              ? "insertConfiguration__option"
              : "insertConfiguration__option--vertical"
          }
          ${allowRotate() ? "" : "insertConfiguration__optionDisabled"}`}
      >
        <ReactSVG src={RotateIcon} />
        <Typography variant="caption">{t("InsertConfiguration_ActionButtons_RotateButton")}</Typography>
      </div>
      <div className="insertConfiguration__optionSeparator"></div>
      <div
        onClick={handleEraseConfirm}
        className={`
          ${
            userDesignData?.userCustomDesign.isHorizontal!
              ? "insertConfiguration__option"
              : "insertConfiguration__option--vertical"
          }
          ${!allowErase() ? "insertConfiguration__optionDisabled" : ""}`}
      >
        <ReactSVG src={EraseIcon} />
        <Typography variant="caption">{t("InsertConfiguration_ActionButtons_EraseButton")}</Typography>
      </div>
      <div className="insertConfiguration__optionSeparator"></div>
      <CoverActionsMenu
        setInsertDetail={props.setInsertDetail}
        selectedCoverNumber={props.selectedCoverNumber}
        setSelectedCoverNumber={props.setSelectedCoverNumber}
      />
    </div>
  );
};

export default CoverActions;
