import { AxiosResponse } from "axios";
import axiosInstance from "../config/axios";
import { IFrameFold } from "../interfaces/IFrameFold";

const responseBody = (response: AxiosResponse) => response;

const requests = {
  get: (url: string, options?: {}) => axiosInstance.get(url, options).then(responseBody),
};

const FrameFoldService = {
  getFrameFolds: (): Promise<AxiosResponse<IFrameFold[]>> => requests.get(`framefold/`),
};

export default FrameFoldService;
