import { AxiosResponse } from "axios";
import axiosInstance from "../config/axios";
import { IReceivedDesignDimensions } from "../interfaces/IDesignDimensions";

const responseBody = (response: AxiosResponse) => response;

const requests = {
  get: (url: string, options?: {}) => axiosInstance.get(url, options).then(responseBody),
};

const LayoutService = {
  getById: (designId: string): Promise<AxiosResponse<IReceivedDesignDimensions>> =>
    requests.get(`layout/getLayoutCoverByDesignId/${designId}`),
};

export default LayoutService;
