import {
  IDesignDimensions,
  IDesignDimensionsNested,
  IReceivedDesignDimensions,
  IReceivedLayoutDimensions,
} from "../interfaces/IDesignDimensions";

export function transformData(data: IReceivedDesignDimensions): IDesignDimensions {
  let transformed: IDesignDimensions = {};

  function addLayout(obj: IDesignDimensionsNested, layoutData: IReceivedLayoutDimensions) {
    const { childrenLayouts, parentLayoutId, name, id, description, coverId, ...cleanedData } = layoutData;

    if (childrenLayouts && childrenLayouts.length > 0) {
      const layoutObj: IDesignDimensionsNested = {};

      childrenLayouts.forEach((childLayout: any) => {
        addLayout(layoutObj, childLayout);
      });

      obj[name] = layoutObj;
    } else {
      obj[name] = {
        ...cleanedData,
      };
    }
  }

  data.layouts.forEach((item: any) => {
    addLayout(transformed, item.layout);
  });

  return transformed;
}
