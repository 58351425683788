import { Button, ButtonProps, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../../App";
import { ABB, BJE } from "../../../constants/Theme";
import { ReactSVG } from "react-svg";
import { downloadProjectDialogRef } from "./Download/DownloadProjectDialog";
import DownloadIcon from "../Icons/download.svg";
import FitText from "../../../components/FitText/FitText";
import { useEffect, useState } from "react";
import { useStoreState } from "../../../hooks";
import { isEmpty } from "lodash";

interface IButtonProps extends ButtonProps {
  disabled?: boolean;
}

const AbbDownloadProject: React.FC<IButtonProps> = ({ disabled }) => {
  const { t } = useTranslation();
  const ftsTheme = useTheme() as FTSTheme;

  return (
    <Button
      sx={{
        width: 179,
        height: 40,
        borderColor: "#DBDBDB",
      }}
      onClick={() => downloadProjectDialogRef.current?.open()}
      variant="outlined"
      endIcon={
        <ReactSVG
          src={DownloadIcon}
          beforeInjection={(svg) => {
            svg.setAttribute(
              "style",
              `width: 16px; height: 16px; color: ${ftsTheme.icon.color}; fill: ${ftsTheme.icon.color}`
            );
          }}
        />
      }
      disabled={disabled}
    >
      <FitText sx={{ fontSize: 14, fontWeight: 500, color: ftsTheme.icon.color }}>
        {t("DesignSummary_DownloadProject")}
      </FitText>
    </Button>
  );
};

const BjeDownloadProject: React.FC<IButtonProps> = ({ disabled }) => {
  const { t } = useTranslation();
  const ftsTheme = useTheme() as FTSTheme;

  return (
    <Button
      sx={{
        width: 191,
        height: 44,
        borderColor: "#DBDBDB",
      }}
      onClick={() => downloadProjectDialogRef.current?.open()}
      variant="outlined"
      endIcon={
        <ReactSVG
          src={DownloadIcon}
          beforeInjection={(svg) => {
            svg.setAttribute(
              "style",
              `width: 16px; height: 16px; color: ${ftsTheme.icon.color}; fill: ${ftsTheme.icon.color}`
            );
          }}
        />
      }
      disabled={disabled}
    >
      <FitText>{t("DesignSummary_DownloadProject")}</FitText>
    </Button>
  );
};

const DownloadProjectButton: React.FC<IButtonProps> = () => {
  const theme = useTheme() as FTSTheme;
  const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState(true);
  const { productDesigns } = useStoreState((state) => state.designs.data);

  useEffect(() => {
    setIsDownloadButtonDisabled(isEmpty(productDesigns));
  }, [productDesigns]);

  return (
    <>
      {(theme.name == ABB && <AbbDownloadProject disabled={isDownloadButtonDisabled} />) ||
        (theme.name == BJE && <BjeDownloadProject disabled={isDownloadButtonDisabled} />)}
    </>
  );
};

export default DownloadProjectButton;
