export enum ESizes {
  SMALL,
  STANDARD,
  MEDIUM,
  LARGE,
  XLARGE,
}

export enum EFontPixelSize {
  SMALL = 8,
  STANDARD = 10,
  MEDIUM = 12,
  LARGE = 14,
  XLARGE = 24,
}

export enum EFontPTSize {
  SMALL = "8pt",
  STANDARD = "10pt",
  MEDIUM = "12pt",
  LARGE = "14pt",
  XLARGE = "24pt",
}

export const arrLabelSize = [
  EFontPTSize.SMALL,
  EFontPTSize.STANDARD,
  EFontPTSize.MEDIUM,
  EFontPTSize.LARGE,
  EFontPTSize.XLARGE,
];
export const arrFontSize = [
  EFontPixelSize.SMALL,
  EFontPixelSize.STANDARD,
  EFontPixelSize.MEDIUM,
  EFontPixelSize.LARGE,
  EFontPixelSize.XLARGE,
];
