import { Divider, Grid, Typography, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { FC } from "react";
import { getAuthorizeHref } from "../../auth/config";
import { useAuth } from "../../auth/utils";
import "./SessionExpired.css";
import { useTranslation } from "react-i18next";
import GoToLogginButton from "./GoToLoginButton";
import { FTSTheme } from "../../App";

const SessionExpired: FC<{}> = (props) => {
  const { isAuthenticated, actions: auth } = useAuth();
  const { t } = useTranslation();
  const theme = useTheme() as FTSTheme;

  return (
    <>
      {true && (
        <>
          <div className="expired">
            <Grid
              container
              alignContent="flex-start"
              className="expiredContainer"
            >
              <Grid
                item
                md={12}
                sx={{ gap: "16px" }}
                className="expiredDivLogo"
              >
                {theme.name == "bje" && (
                  <img
                    src="/logo.svg"
                    alt="logo"
                    className="expiredLogo"
                  />
                )}
                {theme.name == "abb" && (
                  <img
                    src="/abb-logo.svg"
                    alt="logo"
                    className="session_expired_abb"
                  />
                )}
                <Divider
                  orientation="vertical"
                  sx={{ height: 20, borderWidth: "1px", marginBottom: "3px" }}
                />
                <Typography sx={{ marginBottom: "3px" }}>
                  <span>{t("session_expired.label.customization_center")} </span>
                </Typography>
              </Grid>
              <Grid
                item
                md={12}
                className="expiredPaper"
              >
                <Typography>
                  <p className="title1">{t("session_expired_title")}</p>
                </Typography>
                <Typography>
                  <p className="title2">{t("session_expired_logoutMessage")}</p>
                </Typography>
                <Typography
                  sx={{
                    color: "#868686",
                  }}
                >
                  <p className="message">{t("session_expired_message")}</p>
                </Typography>

                <Divider sx={{ margin: "24px 0px" }} />

                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <GoToLogginButton />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export default SessionExpired;
