import { AxiosResponse } from "axios";
import axiosInstance from "../config/axios";
import { ECompany } from "../enum/ECompany";
import {
  IProductDesignResponse,
  IProductFavoriteDesignResponse,
} from "../interfaces/Project/Response/ProductDesignResponse";
import { BJE } from "../constants/Theme";

const responseBody = (response: AxiosResponse) => response;

const requests = {
  get: (url: string, options?: {}): Promise<AxiosResponse> => axiosInstance.get(url, options).then(responseBody),

  delete: (url: string) => axiosInstance.delete(url).then(responseBody),

  post: (url: string, body: {}, options: {}) => axiosInstance.post(url, body, options).then(responseBody),
};

const FavoriteDesignsService = {
  get: (countryId: number): Promise<AxiosResponse<IProductFavoriteDesignResponse[]>> =>
    requests.get(
      `ProductDesign/favoriteDesigns?CountryId=${countryId}&CompanyId=${process.env.REACT_APP_THEME === BJE ? ECompany.BJE : ECompany.ABB}`
    ),

  getFilteredFavoriteDesigns: (
    FrameColorId: string,
    DesignId: string
  ): Promise<AxiosResponse<IProductFavoriteDesignResponse[]>> =>
    requests.get(
      `ProductDesign/favoriteDesigns?CompanyId=${
        process.env.REACT_APP_THEME === BJE ? ECompany.BJE : ECompany.ABB
      }&FrameColorId=${FrameColorId}&DesignId=${DesignId}`
    ),

  getFavoriteDesignById: (favoriteDesignId: string): Promise<AxiosResponse<IProductDesignResponse>> =>
    requests.get(`ProductDesign/getByFavoriteDesign/${favoriteDesignId}`),

  deleteFavoriteDesignById: (favoriteDesignId: string): Promise<AxiosResponse<IProductDesignResponse>> =>
    requests.delete(`ProductDesign/by-favorite-design/${favoriteDesignId}`),

  checkFavoriteDesign: (payload: any): Promise<AxiosResponse<any>> =>
    requests.post(`ProductDesign/findFavoriteDesigns`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    }),
};

export default FavoriteDesignsService;
