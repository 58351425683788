import { Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../App";
import { ABB, BJE } from "../../constants/Theme";
import { IProjectSummaryDto } from "../../interfaces/DTO/IProjectSummaryDto";
import { ReactComponent as DeleteIcon } from "../../pages/Configurator/Icons/delete-icon.svg";

interface IDotsMenuProps {
  data: IProjectSummaryDto;
  anchorEl: any;
  handleClose: () => void;
  handleEdit: (id: string) => Promise<void>;
  handleDuplicate: (project: IProjectSummaryDto) => void;
  handleDeleteProject: (project: IProjectSummaryDto) => void;
  handleOpenEditProjectName?: () => void;
  enableNameEdit: boolean;
}

const AbbDotsMenu: React.FC<IDotsMenuProps> = ({
  data,
  anchorEl,
  handleClose,
  handleEdit,
  handleDuplicate,
  handleDeleteProject,
  enableNameEdit,
  handleOpenEditProjectName,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Menu
        id="recentProjectMenu"
        anchorEl={anchorEl}
        keepMounted
        onClick={handleClose}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "180px",
            border: "1px solid var(--grey-20, #DBDBDB)",
          },
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {enableNameEdit ? (
          <MenuItem
            sx={{ height: 47 }}
            onClick={handleOpenEditProjectName}
          >
            <Typography sx={{ fontSize: 14, fontWeight: 400 }}>{t("DesignSummary_ModalTitle")}</Typography>
          </MenuItem>
        ) : (
          false
        )}

        <hr style={{ color: "##DBDBDB", margin: 0 }} />
        <MenuItem
          sx={{ height: 47 }}
          onClick={() => handleEdit(data.id)}
        >
          <Typography sx={{ fontSize: 14, fontWeight: 400 }}>{t("edit")}</Typography>
        </MenuItem>

        <hr style={{ color: "##DBDBDB", margin: 0 }} />

        <MenuItem
          sx={{ height: 47 }}
          onClick={() => handleDuplicate(data)}
        >
          <Typography sx={{ fontSize: 14, fontWeight: 400 }}>{t("duplicate")}</Typography>
        </MenuItem>

        <hr style={{ color: "##DBDBDB", margin: 0 }} />

        <MenuItem
          sx={{ height: 47, color: "#FF000F" }}
          onClick={() => handleDeleteProject(data)}
        >
          <DeleteIcon />
          <Typography sx={{ marginLeft: "5px", fontSize: 14, fontWeight: 400 }}>{t("deleteProject")}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

const BjeDotsMenu: React.FC<IDotsMenuProps> = ({
  data,
  anchorEl,
  handleClose,
  handleEdit,
  handleDuplicate,
  handleDeleteProject,
  enableNameEdit,
  handleOpenEditProjectName,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Menu
        id="recentProjectMenu"
        anchorEl={anchorEl}
        keepMounted
        onClick={handleClose}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "215px",
            border: "1px solid var(--grey-20, #DBDBDB)",
          },
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {enableNameEdit ? (
          <MenuItem onClick={handleOpenEditProjectName}>
            <Typography sx={{ fontSize: 16, fontWeight: 400 }}>{t("DesignSummary_ModalTitle")}</Typography>
          </MenuItem>
        ) : (
          false
        )}

        <MenuItem onClick={() => handleEdit(data.id)}>
          <Typography sx={{ fontSize: 16, fontWeight: 400 }}>{t("edit")}</Typography>
        </MenuItem>

        <MenuItem onClick={() => handleDuplicate(data)}>
          <Typography sx={{ fontSize: 16, fontWeight: 400 }}>{t("duplicate")}</Typography>
        </MenuItem>

        <MenuItem
          sx={{ color: "#FF000F" }}
          onClick={() => handleDeleteProject(data)}
        >
          <Typography sx={{ fontSize: 16, fontWeight: 400 }}>{t("deleteProject")}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

const DotsMenu: React.FC<IDotsMenuProps> = (rest) => {
  const theme = useTheme() as FTSTheme;

  return <>{(theme.name == ABB && <AbbDotsMenu {...rest} />) || (theme.name == BJE && <BjeDotsMenu {...rest} />)}</>;
};

export default DotsMenu;
