import { Link, Typography, useTheme } from "@mui/material";
import { hasWhiteSpace } from "../../../utils/stringHelper";
import TruncateString from "truncate-string-react";
import { FTSTheme } from "../../../App";
import { ABB } from "../../../constants/Theme";

interface IProjectLinkProps {
  projectId: string;
  projectName: string;
  handleOpenProject: (projectId: string) => void;
}

const AbbProjectLink: React.FC<IProjectLinkProps> = (props) => {
  return (
    <>
      <Link
        sx={{ cursor: "pointer", textDecoration: "none" }}
        onClick={() => props.handleOpenProject(props.projectId)}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: "bold", color: "#000000" }}>
          {!hasWhiteSpace(props.projectName) ? (
            <TruncateString
              string={props.projectName}
              length={15}
              typeCount={3}
              type="."
            />
          ) : (
            (props.projectName ?? "N/A")
          )}
        </Typography>
      </Link>
    </>
  );
};

const BjeProjectLink: React.FC<IProjectLinkProps> = (props) => {
  return (
    <>
      <Link
        sx={{ cursor: "pointer", textDecoration: "none" }}
        onClick={() => props.handleOpenProject(props.projectId)}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
          {!hasWhiteSpace(props.projectName) ? (
            <TruncateString
              string={props.projectName}
              length={15}
              typeCount={3}
              type="."
            />
          ) : (
            (props.projectName ?? "N/A")
          )}
        </Typography>
      </Link>
    </>
  );
};

const ProjectLink: React.FC<IProjectLinkProps> = (props) => {
  const ftsTheme = useTheme() as FTSTheme;
  return <>{ftsTheme.name == ABB ? <AbbProjectLink {...props} /> : <BjeProjectLink {...props} />}</>;
};

export default ProjectLink;
