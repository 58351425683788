import { Grid, GridProps } from "@mui/material";
import "./tile-55.css";
import "./tile-62.css";
import "./tile-70.css";
import "./tile-54.css";
import "./tile-59.css";
import { FrameStyleProps } from "../../../../interfaces/IFrameAndCoverStyle";

interface IStandardTileProps extends GridProps {
  children?: React.ReactNode;
  designCode: string;
}

const StandardTile: React.FC<IStandardTileProps> = (props) => {
  const currentDesign = props.designCode ?? "caret";
  const coverStyle = FrameStyleProps.get(currentDesign);
  return (
    <>
      <Grid
        container
        direction={props.direction}
        justifyContent="center"
        className={coverStyle?.tile.className}
      >
        {props.children}
      </Grid>
    </>
  );
};

export default StandardTile;
