import React, { createRef, forwardRef, useImperativeHandle, useState } from "react";
import { Dialog, DialogContent, IconButton, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { useStoreActions, useStoreState } from "../../hooks";
import CloseIcon from "../Configurator/Icons/close.svg";
import "./FavoriteDesignsPreview.css";

import { FTSTheme } from "../../App";
import DesignPreviewTabs from "../Configurator/DesignPreview/DesignPreviewTabs";
import { IDialogHandle } from "../../interfaces/IDialogHandle";
import { INSERT_CONFIGURATION_PATH } from "../../constants/Paths";
import { ICustomDesign } from "../../interfaces/IUserDesignData";

export const favoriteDesignsPreviewRef = createRef<IDialogHandle>();

interface IFavoriteDesignsPreviewProps {
  displayPreviewTabs?: boolean;
  previewData?: ICustomDesign;
  designName?: string;
}

const FavoriteDesignsPreview = forwardRef<IDialogHandle, IFavoriteDesignsPreviewProps>(
  ({ displayPreviewTabs, previewData, designName }, ref) => {
    const { t } = useTranslation();
    const ftsTheme = useTheme() as FTSTheme;
    const currentPath = window.location.pathname;
    const { userDesignData } = useStoreState((state) => state.designs.configuration);
    const { setProductDesignPreview } = useStoreActions((actions) => actions.designs);
    const { setUserDesignData } = useStoreActions((actions) => actions.designs);
    const [open, setOpen] = useState(false);
    const isInsertConfigurationPage = currentPath === INSERT_CONFIGURATION_PATH;
    const dataToDisplay = previewData ? previewData : userDesignData?.userCustomDesign;

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
      setProductDesignPreview(null);
      !isInsertConfigurationPage && setUserDesignData(null);
      setOpen(false);
    };

    useImperativeHandle(ref, () => ({ open: handleOpen, close: handleClose }));

    if (!dataToDisplay) {
      return null;
    }
    return (
      <Dialog
        onClose={handleClose}
        fullWidth
        open={open}
        className="favoriteDesignsPreviewDialog"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          color="primary"
          className="closeIcon"
        >
          <ReactSVG
            src={CloseIcon}
            beforeInjection={(svg) => {
              svg.setAttribute("class", "svgIcon");
              svg.style.fill = ftsTheme.icon.color;
            }}
          />
        </IconButton>
        <DialogContent>
          <Typography
            variant="h2"
            fontSize="24px"
            fontWeight={700}
            mb={2}
          >
            {t("design_preview_dialog_preview_title")}
          </Typography>
          {!dataToDisplay ? (
            <p>{t("DesignSummaryDialog_NoContent")}</p>
          ) : (
            <div className="favoriteDesignsPreviewContent">
              <div className="favoriteDesignsPreviewBackground favoriteDesignsPreview__mainPreview--horizontal-dialog">
                <img
                  src={dataToDisplay?.coverImage}
                  alt={`${designName}, ${t(`insertcolors.${dataToDisplay?.insertDetails?.[0]?.insertColor?.code}`)}`}
                  width="100%"
                />
              </div>
            </div>
          )}
          {displayPreviewTabs && <DesignPreviewTabs />}
        </DialogContent>
      </Dialog>
    );
  }
);

export default FavoriteDesignsPreview;
