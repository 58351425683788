import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useRef, useState } from "react";
import { Checkbox, FormControlLabel, useTheme } from "@mui/material";
import { IProductDesignResponse } from "../../interfaces/Project/Response/ProductDesignResponse";
import { useStoreActions, useStoreState } from "../../hooks";
import { useTranslation } from "react-i18next";
import { IInsertDetail } from "../../interfaces/IInsertDetail";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { toPng } from "html-to-image";
import Frame from "../../pages/Configurator/Components/Frame/Frame";
import Cover from "../../pages/Configurator/Components/Cover/Cover";
import LayoutConfiguration from "../../pages/Configurator/InsertConfiguration/Tiles/LayoutConfiguration";
import { INSERT_DETAILS_SINGLE_ELEMENT, NUMBER_OF_SINGLE_SWITCH } from "../../constants/NumberValues";
import { IFoundFavoriteDesigns } from "../../interfaces/IFoundFavoriteDesigns";

interface Props {
  item: IInsertDetail;
  checkedFavoriteDesigns: IFoundFavoriteDesigns[];
  setCheckedFavoriteDesigns: React.Dispatch<React.SetStateAction<IFoundFavoriteDesigns[]>>;
  numberOfCover: number;
}

const FavoriteDesignsCheckbox: React.FC<Props> = ({
  item,
  checkedFavoriteDesigns,
  setCheckedFavoriteDesigns,
  numberOfCover,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { currentUser } = useStoreState((state) => state.auth);
  const { productDesigns } = useStoreState((state) => state.designs.data);
  const { thunkAddFavoriteDesign, thunkDeleteFavoriteDesignById } = useStoreActions((actions) => actions.designs);
  const { userDesignData } = useStoreState((state) => state.designs.configuration);
  const [coverImage, setCoverImage] = useState<string>("");
  const [favoriteCoverWithFrame, setFavoriteCoverWithFrame] = useState<IProductDesignResponse>();
  const [newFavoriteDesign, setNewFavoriteDesign] = useState<IProductDesignResponse>();
  const thumbRef = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const isChecked =
    checkedFavoriteDesigns?.some((design) => design?.insertId === item.id && design?.favoriteId) || false;

  useEffect(() => {
    checkedFavoriteDesigns.length ? setIsLoading(false) : setIsLoading(true);
  }, [checkedFavoriteDesigns]);

  useEffect(() => {
    const generateImage = () => {
      if (thumbRef.current === null) {
        return;
      }

      toPng(thumbRef.current)
        .then((dataUrl) => {
          setCoverImage(dataUrl);
        })
        .catch((error) => {
          console.error("Error generating image:", error);
        });
    };

    generateImage();
  }, [thumbRef.current, favoriteCoverWithFrame]);

  useEffect(() => {
    const updateFavoriteDesigns = async () => {
      if (newFavoriteDesign && coverImage) {
        try {
          await thunkAddFavoriteDesign({ ...newFavoriteDesign, coverImage: coverImage });
          if (checkedFavoriteDesigns.length) {
            setCheckedFavoriteDesigns((prev) =>
              prev.map((checkedFavoriteDesign) => {
                if (checkedFavoriteDesign.insertId === favoriteCoverWithFrame?.insertDetails[0].id) {
                  return { ...checkedFavoriteDesign, favoriteId: newFavoriteDesign.favoriteDesignId as string };
                }
                return checkedFavoriteDesign;
              })
            );
          } else {
            const productDesign = getActualProductDesign();
            const newCheckedFavoriteDesigns = Array(productDesign?.insertDetails?.length).fill(null);
            newCheckedFavoriteDesigns[numberOfCover] = newFavoriteDesign.favoriteDesignId;
            setCheckedFavoriteDesigns(newCheckedFavoriteDesigns);
          }
        } catch (error) {
          // TODO: remove from checked arr if sth went wrong
          console.error("Error updating favorite designs:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    updateFavoriteDesigns();
  }, [coverImage, newFavoriteDesign, thunkAddFavoriteDesign]);

  const getActualProductDesign = () => {
    let productDesign;
    productDesign = productDesigns.find((productDesign) =>
      productDesign.insertDetails.some((insertDetail) => insertDetail.id === item.id)
    );

    if (!productDesign) {
      const incompleteProductDesign = productDesigns.find(
        (productDesign) => productDesign.id === userDesignData?.userCustomDesign.id
      );
      productDesign = {
        ...incompleteProductDesign,
        insertDetails: userDesignData?.userCustomDesign.insertDetails,
      };
    }
    return productDesign;
  };

  const handleMarkAsFavoriteDesign = (id: string, selectedCoverNumber: number) => {
    const productDesign = getActualProductDesign();
    if (!checkedFavoriteDesigns) return;

    const favoriteCover = productDesign.insertDetails[selectedCoverNumber];
    const fullFavoriteCover = { ...productDesign, insertDetails: [favoriteCover] };
    setFavoriteCoverWithFrame(fullFavoriteCover);

    const favId = uuidv4();
    const newFavoriteDesign: IProductDesignResponse = {
      ...productDesign,
      insertDetails: [{ ...favoriteCover }],
      favoriteDesignId: favId,
      comment: "",
      project: {
        ...productDesign.project,
        countryId: currentUser?.countryId,
      },
    };

    setNewFavoriteDesign(newFavoriteDesign);
  };

  const handleCheckboxChange = async () => {
    setIsLoading(true);
    if (isChecked) {
      const designToDelete = checkedFavoriteDesigns.find(
        (checkedFavoriteDesign) => checkedFavoriteDesign.insertId === item.id
      );

      if (designToDelete) {
        try {
          await thunkDeleteFavoriteDesignById(designToDelete?.favoriteId as string);

          setCheckedFavoriteDesigns((prev) =>
            prev.map((checkedFavoriteDesign) => {
              if (checkedFavoriteDesign.insertId === designToDelete?.insertId) {
                return { ...checkedFavoriteDesign, favoriteId: null };
              }
              return checkedFavoriteDesign;
            })
          );
        } catch (error) {
          console.error("Error updating favorite designs:", error);
        } finally {
          setIsLoading(false);
        }
      }
    } else {
      handleMarkAsFavoriteDesign(item.id as string, numberOfCover);
    }
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            name="pdf"
            checked={isChecked}
            onChange={handleCheckboxChange}
            icon={<FavoriteBorder />}
            checkedIcon={<Favorite />}
            disabled={isLoading}
            sx={{
              color: "#000",
              "&.Mui-checked": {
                color: theme.palette.primary.main,
              },
              "&.Mui-disabled": {
                opacity: 0.5,
              },
              "& .MuiSvgIcon-root": {
                opacity: isLoading ? 0.5 : 1,
              },
            }}
          />
        }
        label={t("design_preview.button.add_favorite_design")}
      />
      {favoriteCoverWithFrame && (
        <div className="designPreview__favoriteDesign-hiddenContainer">
          <div className="designPreview__favoriteDesign-hiddenDiv">
            <div ref={thumbRef}>
              <div
                className={
                  userDesignData?.userCustomDesign.isHorizontal
                    ? `designPreview__mainPreviewImage--horizontal`
                    : `designPreview__mainPreviewImage--vertical`
                }
              >
                <Frame
                  numberOfSwitches={NUMBER_OF_SINGLE_SWITCH}
                  isHorizontal={userDesignData?.userCustomDesign.isHorizontal!}
                  design={userDesignData?.design!}
                  frameColor={userDesignData?.userCustomDesign.frameColor!}
                  enableSelection={false}
                  enableTransition={false}
                >
                  <Cover
                    key={`cover-${INSERT_DETAILS_SINGLE_ELEMENT}`}
                    cover={INSERT_DETAILS_SINGLE_ELEMENT}
                    designCode={favoriteCoverWithFrame?.design?.code!}
                    isHorizontal={favoriteCoverWithFrame.isHorizontal!}
                    insertDetail={favoriteCoverWithFrame.insertDetails[0] as IInsertDetail}
                  >
                    <LayoutConfiguration
                      designCode={favoriteCoverWithFrame!.design?.code!}
                      isHorizontal={favoriteCoverWithFrame!.isHorizontal!}
                      key={`layout-configuration-preview-${INSERT_DETAILS_SINGLE_ELEMENT}`}
                      insertDetail={favoriteCoverWithFrame.insertDetails[INSERT_DETAILS_SINGLE_ELEMENT]}
                      setInsertDetail={(detail: IInsertDetail) => {}}
                      cover={INSERT_DETAILS_SINGLE_ELEMENT}
                      insertType={favoriteCoverWithFrame.insertDetails[INSERT_DETAILS_SINGLE_ELEMENT].insertType?.name}
                      isPreview={true}
                      canManageRef={false}
                    />
                  </Cover>
                </Frame>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FavoriteDesignsCheckbox;
