import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, Link, Link as LinkMui, Paper, useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FTSTheme } from "../../App";
import { getSignOutHref } from "../../auth/config";
import { useAuth } from "../../auth/utils";
import { BJE } from "../../constants/Theme";
import logging from "../../config/logging";
import { useStoreState } from "../../hooks";
import store from "../../store";
import NavLink from "./NavLink";
import "./Navbar.css";

interface INavbarProps {
  mainTitle?: string;
  backgroundColor?: string;
  logoLink: string;
  showRegionBtn: boolean;
  showNavbarLinks: boolean;
  navbarCenteredText?: string;
  showNavbarCenteredText: boolean;
}

const Navbar: FC<INavbarProps> = ({
  backgroundColor,
  mainTitle,
  logoLink,
  showNavbarLinks,
  navbarCenteredText,
  showNavbarCenteredText,
}: INavbarProps) => {
  const { isGuest } = useStoreState((state) => state.auth);
  const { isAuthenticated, user, actions: auth } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme() as FTSTheme;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const logoSrc = theme.name === BJE ? "/logo.svg" : "/abb-logo.svg";

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    store.persist
      .clear()
      .then(() => {
        auth.removeAllData();
        window.location.replace(getSignOutHref());
      })
      .catch((err) => logging.error(err));
  };

  return (
    <header>
      <nav
        className="navbar"
        style={{ backgroundColor: backgroundColor || "#fff" }}
      >
        <div className="navbar__logoTitleContainer">
          <div className="navbar__logo">
            <Link
              onClick={() => navigate("/dashboard")}
              sx={{ cursor: "pointer", textDecoration: "none" }}
            >
              {logoSrc && (
                <img
                  src={logoSrc}
                  alt="logo"
                  className={theme.name}
                />
              )}
            </Link>
          </div>
          <div className="navbar__title">
            <Typography variant="h1">{t(`${theme.name}.${mainTitle!}`)}</Typography>
          </div>
        </div>

        <div className="navbar__menu">
          {showNavbarLinks && (
            <>
              <NavLink
                translationKey="dashboard"
                path="/dashboard"
              />
              {/* <NavLink translationKey="productsCatalog" path="/" /> */}
              <NavLink
                translationKey="myProjects"
                path="/projects"
              />
              <NavLink
                translationKey="favoriteDesigns"
                path="/favoriteDesigns"
              />
              <div className="link__separator"></div>
            </>
          )}
          {/* <Link
            to="/checkout"
            className="shoppingCart__link"
          >
            <ShoppingCartIcon
              width={24}
              height={24}
            />
            <Typography variant="body1">{t("cart")}</Typography>
          </Link> */}
          <div className="navbar__user">
            {isAuthenticated && (
              <>
                <IconButton
                  id="navbar__userMenuBtn"
                  aria-controls={open ? "navbar__userMenu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <Avatar
                    sx={{
                      width: 32,
                      height: 32,
                      fontSize: 14,
                      lineHeight: 18,
                      bgcolor: "#EDEDED",
                      color: "#000000",
                      fontWeight: 700,
                    }}
                  >
                    {isAuthenticated && !isGuest && user != null
                      ? user.firstname[0].toUpperCase() + user.lastname[0].toUpperCase()
                      : " G"}
                  </Avatar>
                  {(open && <ExpandLessIcon />) || <ExpandMoreIcon />}
                </IconButton>
                <Menu
                  id="navbar__userMenu"
                  aria-labelledby="navbar__userMenuBtn"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      width: "325px",
                      height: "149px",
                    }}
                  >
                    <Grid
                      container
                      p={"22px 18px"}
                    >
                      <Grid
                        item
                        md={4}
                      >
                        <Avatar
                          sx={{
                            width: 68,
                            height: 68,
                            fontSize: 24,
                            lineHeight: 18,
                            bgcolor: "#EDEDED",
                            color: "#000000",
                            fontWeight: 400,
                          }}
                        >
                          {isAuthenticated && !isGuest && user != null
                            ? user.firstname[0].toUpperCase() + user.lastname[0].toUpperCase()
                            : " G"}
                        </Avatar>
                      </Grid>
                      <Grid
                        item
                        md={8}
                      >
                        <Typography
                          variant="h6"
                          fontSize={"16px"}
                        >
                          {user != null ? `${user.firstname} ${user.lastname}` : " G"}
                        </Typography>
                        {user != null && (
                          <>
                            <Typography
                              variant="body1"
                              fontSize={"13px"}
                            >
                              {user.email}
                            </Typography>
                            <LinkMui
                              onClick={() => navigate("/profile")}
                              sx={{ cursor: "pointer", textDecoration: "none" }}
                            >
                              <Typography
                                sx={{ textDecoration: "underline" }}
                                mt={"20px"}
                                color="InfoText"
                                variant="body1"
                                fontSize={"13px"}
                              >
                                {t("accountSettings")}
                              </Typography>
                            </LinkMui>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper
                    onClick={handleSignOut}
                    elevation={0}
                    sx={{
                      backgroundColor: "#D9D9D9",
                      cursor: "pointer",
                      textAlign: "center",
                      borderTopLeftRadius: "0",
                      borderTopRightRadius: "0",
                    }}
                  >
                    <Grid
                      sx={{ height: "42px" }}
                      container
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography>{t("navbar.user_menu.label.signout")}</Typography>
                    </Grid>
                  </Paper>
                </Menu>
                {/* <Menu
                  id="navbar__userMenu"
                  aria-labelledby="navbar__userMenuBtn"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <Typography
                    style={{
                      padding: "3px 12px",
                      userSelect: "none",
                      fontWeight: 500,
                      fontFamily: "ABBvoice",
                      fontSize: "16px",
                    }}
                    variant="body2"
                    gutterBottom
                  >
                    {t("hello")},
                    {isAuthenticated && !isGuest && user != null
                      ? " " + user.firstname
                      : " Guest"}
                  </Typography>
                  <Divider
                    style={{
                      margin: "5px 12px",
                      borderColor: "rgba(0, 0, 0, 1)",
                    }}
                  />
                  <MenuItem dense>{t("myOrders")}</MenuItem>
                  <MenuItem dense>{t("myProductDesigns")}</MenuItem>
                  <MenuItem dense>{t("myTemplates")}</MenuItem>
                  <MenuItem dense>{t("accountSettings")}</MenuItem>
                  <MenuItem onClick={handleSignOut} dense>
                    {t("logout")}
                  </MenuItem>
                </Menu> */}
              </>
            )}
          </div>
          {/* {showRegionBtn && (
            <IconButton
              className="region__btn"
              aria-label="Choose region"
              component="span"
              onClick={() => setOpenCountryDialog(true)}
            >
              <LanguageIcon fontSize="medium" />
            </IconButton>
          )} */}
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
